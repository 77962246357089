import { gql } from "@apollo/client";
import { PostSeoFragment } from "@/data/wp/operations/queries/fragments/seo";
import { TemplateFragment } from "@/data/wp/operations/queries/fragments/template";
import ImageFragment from "@/data/wp/operations/queries/fragments/image";
import { LanguageFragment, TranslationsPartial } from "@/data/wp/operations/queries/fragments/languages";
import VideoPartial from "@/data/wp/operations/queries/fragments/video";

export const GET_POST = gql`
	query GET_POST($language: LanguageCodeFilterEnum, $slug: String) {
    posts(where: {language: $language, name: $slug }) {
      nodes {
        id
        title
        content
        slug
        uri
        language {
          ...LanguageFragment
        }
        ${TranslationsPartial}
        categories {
          edges {
            node {
              id
              name
              slug
              parentId
            }
          }
        }
        layout {
          layout
        }
        fieldsArticoli {
          date
          pdf {
            id
            mediaItemUrl
            title
            altText
            caption
            mimeType
            date
            fileSize
          }
          flipbookUrl: flipbookurl
        }
        fieldsRealizzazioni {
          locality
          info {
            label
            value
          }
        }
        fieldsGalleria {
          galleria {
            mediatype
            image {
              ...ImageFragment
            }
            video {
              ${VideoPartial}
            }
          }
        }
        seo {
          ...PostSeoFragment
        }
        template {
          ...TemplateFragment
        }
      }
    }
	}

  ${LanguageFragment}
  ${PostSeoFragment}
  ${TemplateFragment}
  ${ImageFragment}
`;
