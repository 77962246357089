export const LanguageFragment = `
  fragment LanguageFragment on Language {
    id
    code
    locale
    slug
    name
  }
`;

export const LanguagesPartial = `
  languages: languages {
    ...LanguageFragment
  }
`;

export const TranslationsPartial = `
  translations {
    id
    slug
    uri
    language {
      ...LanguageFragment
    }
  }
`;

export interface WPLanguage {
  id: string
  code: string
  locale: string
  slug: string
  name: string
}

export interface WPTranslation {
  id: string
  slug: string
  uri: string
  language: WPLanguage
}
