import LinksPartial, { WPLink } from "./link";
import { WPImage } from "./image";

const FasciaWideBannersPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_WideBanners`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      title1
      text1
      immagine1Desktop: immagine1desktop {
        ...ImageFragment
      }
      immagine1Mobile: immagine1mobile {
        ...ImageFragment
      }
      links1Links {
        ${LinksPartial(`${fasciaBaseType}_Links1Links`)}
      }
      title2
      text2
      immagine2Desktop: immagine2desktop {
        ...ImageFragment
      }
      immagine2Mobile: immagine2mobile {
        ...ImageFragment
      }
      links2Links {
        ${LinksPartial(`${fasciaBaseType}_Links2Links`)}
      }
    }
  `
}

export default FasciaWideBannersPartial;

export interface WPWideBanners {
  title1: string
  text1: string
  immagine1Desktop: WPImage
  immagine1Mobile: WPImage
  links1Links: WPLink[]
  title2: string
  text2: string
  immagine2Desktop: WPImage
  immagine2Mobile: WPImage
  links2Links: WPLink[]
}
