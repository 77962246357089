import { gql } from "@apollo/client";
import { PostSeoFragment } from "@/data/wp/operations/queries/fragments/seo";
import { TemplateFragment } from "@/data/wp/operations/queries/fragments/template";
import ImageFragment from "@/data/wp/operations/queries/fragments/image";
import { LanguageFragment, TranslationsPartial } from "@/data/wp/operations/queries/fragments/languages";

export const GET_PAGE = gql`
	query GET_PAGE($language: LanguageCodeFilterEnum, $slug: String) {
    pages(where: {language: $language, name: $slug }) {
      nodes {
        id
        title
        content
        slug
        uri
        language {
          ...LanguageFragment
        }
        ${TranslationsPartial}
        seo {
          ...PostSeoFragment
        }
        template {
          ...TemplateFragment
        }
      }
	  }
	}

  ${LanguageFragment}
  ${PostSeoFragment}
  ${TemplateFragment}
  ${ImageFragment}
`;
