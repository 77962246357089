import { WPImage } from "./image"

export const PostSeoFragment = `
fragment PostSeoFragment on PostTypeSEO {
  title
  canonical
  metaDesc
  metaKeywords
  metaRobotsNoindex
  metaRobotsNofollow
  opengraphAuthor
  opengraphDescription
  opengraphTitle
  opengraphImage {
    ...ImageFragment
  }
  opengraphSiteName
  opengraphPublishedTime
  opengraphModifiedTime
  twitterTitle
  twitterDescription
  #schema {
  #  raw
  #}
}
`

export const TaxonomySeoFragment = `
fragment TaxonomySeoFragment on TaxonomySEO {
  title
  canonical
  metaDesc
  metaKeywords
  metaRobotsNoindex
  metaRobotsNofollow
  opengraphAuthor
  opengraphDescription
  opengraphTitle
  opengraphImage {
    ...ImageFragment
  }
  opengraphSiteName
  opengraphPublishedTime
  opengraphModifiedTime
  twitterTitle
  twitterDescription
  #schema {
  #  raw
  #}
}
`

export interface WPPostTypeSeo {
  title?: string,
  canonical?: string
  metaDesc?: string
  metaKeywords?: string
  metaRobotsNoindex?: string
  metaRobotsNofollow?: string
  opengraphAuthor?: string
  opengraphDescription?: string
  opengraphTitle?: string
  opengraphImage?: WPImage
  opengraphSiteName?: string
  opengraphPublishedTime?: string
  opengraphModifiedTime?: string
  twitterTitle?: string
  twitterDescription?: string
  // schema?: {
  //   raw?: string
  // }
}
