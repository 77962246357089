import { gql } from "@apollo/client";
import MenuFragment from "@/data/wp/operations/queries/fragments/menus";
import { LanguageFragment, LanguagesPartial } from "./fragments/languages";

export const HeaderFooterPartial = `
  mainMenus: menuItems(where: {language: $language, location: PRIMARY, parentId: "0"}) {
    edges {
      node {
        ...MenuFragment
        childItems(first: 50) {
          edges {
            node {
              ...MenuFragment
              fieldsMenu {
                viewalllabel
              }
              childItems(first: 50) {
                edges {
                  node {
                    ...MenuFragment
                    fieldsMenu {
                			icon
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  serviceMenus: menuItems(where: {language: $language, location: SERVICE, parentId: "0"}) {
    edges {
      node {
        ...MenuFragment
        childItems(first: 50) {
          edges {
            node {
              ...MenuFragment
            }
          }
        }
      }
    }
  }
  footerMenus: menuItems(where: {language: $language, location: FOOTER, parentId: "0"}) {
    edges {
      node {
        ...MenuFragment
        childItems(first: 50) {
          edges {
            node {
              ...MenuFragment
            }
          }
        }
      }
    }
  }
  socialLinks: menuItems(where: {language: $language, location: SOCIALS, parentId: "0"}) {
    edges {
      node {
        ...MenuFragment
        fieldsSocialMenu {
          icon
        }
      }
    }
  }
`

export const GET_MENUS = gql`
  query GET_MENUS ($language: LanguageCodeFilterEnum) {
    ${LanguagesPartial}
    ${HeaderFooterPartial}
  }
  ${LanguageFragment}
  ${MenuFragment}
`

export const GET_SIDEBAR_MENUS = (sideMenus: any[]) => {
  let query = `query GET_SIDEBAR_MENUS ($language: LanguageCodeFilterEnum) {`;
  sideMenus.forEach((item) => {
    const location = item.navMenuLocation.replace(/-/g, '_').toUpperCase();
    query += `
      ${location}: menuItems(
        first: 50,
        where: {
          language: $language,
          location: ${location},
          parentId: "0"
        }
      ) {
        edges {
          node {
            ...MenuFragment
          }
        }
      }
    `;
  });
  query += `
    }
    ${MenuFragment}
  `;
  return gql(query);
}
