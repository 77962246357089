const FasciaNewsEventiFiereListPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_NewsEventiFiereList`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      category {
        id
        databaseId
        name
        slug
      }
      showCategoryFilters: showcategoryfilters
      showFeaturedOnly: showfeaturedonly
      sliderMode: slidermode
      title
    }
  `
}

export default FasciaNewsEventiFiereListPartial;

export interface WPNewsEventiFiereList {
  category: {
    id: string
    databaseId: number
    name: string
    slug: string
  }
  showCategoryFilters?: boolean
  showFeaturedOnly?: boolean
  sliderMode?: boolean
  title?: string
}
