import { WPImage } from "./image";
import LinksPartial, { WPLink } from "./link";

const FasciaShowroomListPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_ShowroomList`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      columns {
        title
        desc
        img {
          ...ImageFragment
        }
        links {
          ${LinksPartial(`${fasciaBaseType}_columns_Links`)}
        }
      }
    }
	`
}

export default FasciaShowroomListPartial;

export interface WPShowroomList {
  columns?: {
    title?: string;
    desc?: string;
    img?: WPImage;
    links?: WPLink[];
  }[],
}
