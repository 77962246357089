import { WPImage } from "./image";
import LinksPartial, { WPLink } from "./link";

const FasciaFullDoubleColumnPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_FullDoubleColumn`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      backgroundImage: backgroundimage {
        ...ImageFragment
      }
      title
      desc
      desc2
      links {
        ${LinksPartial(`${fasciaBaseType}_Links`)}
      }
    }
	`
}

export default FasciaFullDoubleColumnPartial;

export interface WPFullDoubleColumn {
  backgroundImage: WPImage;
  title?: string;
  desc?: string;
  desc2?: string;
  links?: WPLink[];
}
