const FasciaNormativePartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_Normative`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      title
      subtitle
      desc
      icons {
        icon
        title
        desc
      }
      normative {
        normativeName: normativename
        normativeDesc: normativedesc
        normativePoints: normativepoints {
          normativePointsName: normativepointsname
          normativePointsValue: normativepointsvalue
        }
      }
    }
	`
}

export default FasciaNormativePartial;

export interface WPNormativeIcon {
  icon?: string;
  title?: string;
  desc?: string;
}

export interface WPNormative {
  title?: string;
  subtitle?: string;
  desc?: string;
  icons?: WPNormativeIcon[];
  normative?: {
    normativeName?: string;
    normativeDesc?: string;
    normativePoints?: {
      normativePointsName?: string;
      normativePointsValue?: string;
    }[];
  }[],
}
