
const FasciaSearchHeadingPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_SearchHeading`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      mode
      title
    }
	`
}

export default FasciaSearchHeadingPartial;

export enum WPSearchHeadingMode {
  backend = 'backend',
  inpage = 'inpage',
}

export interface WPSearchHeading {
  mode?: WPSearchHeadingMode
  title?: string
}
