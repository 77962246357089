import client from "@/data/wp/apollo/client";
import { getLanguageCodeFromNextLocale } from '@/utils/localization';
import { getCategoryRawData, loadDataForFasce, loadStructuralData } from "@/data/wp/sdk/utils";
import { GET_MENUS } from "@/data/wp/operations/queries/get-menus";
import { GET_PAGE } from "@/data/wp/operations/queries/get-page";
import { GET_POST } from "@/data/wp/operations/queries/get-post";
import { GET_PAGES } from "@/data/wp/operations/queries/get-pages";
import { GET_POSTS, GET_POSTS_IN_CATEGORIES } from "../operations/queries/get-posts";
import { GET_CATEGORIES } from "../operations/queries/get-categories";
import { SEARCH } from "../operations/queries/search";
import { performanceLog, performanceStart } from "@/utils/performance";
import { getStore } from "@/utils/storeInfo";

/**
 * Effettua la query GET_MENUS per reperire le info relative a header e footer.
 * Nota: non usare direttamente, usare invece loadStructuralData per arricchire data._structuralData.
 */
export const getStructuralData = async (locale: string) => {
  return client.query({
    query: GET_MENUS,
    variables: {
      language: getLanguageCodeFromNextLocale(locale),
    },
  });
}

export const getPageData = async (locale: string, slug: string, params: any) => {
  let data: any = {}, errors: any;
  let t0 = performanceStart();

  // Load page content info
  const pageQuery = client.query({
    query: GET_PAGE,
    variables: {
      language: getLanguageCodeFromNextLocale(locale),
      slug,
    },
  });

  const storeQuery = getStore(locale);

  // Load structural data for header & footer
  const structuralQuery = loadStructuralData(data, locale);

  await Promise.all([
    pageQuery,
    storeQuery,
    structuralQuery
  ]).then((values) => {
    const { data: pageData, errors: pageErrors } = values[0];
    data = {
      ...data,
      ...pageData
    };
    errors = pageErrors;
    performanceLog(t0, `getPageData -> Promise.all()`, {locale, params});
  });

  if (data) {
    // Load additional data required by certain fasce
    const node = data.pages?.nodes?.[0];
    const fasce = node?.template?.fasce;
    t0 = performanceStart();
    await loadDataForFasce(locale, data._structuralData, node, fasce, params);
    performanceLog(t0, `getPageData -> loadDataForFasce()`, {locale, params});
  }

  return { data, errors };
}

export const getPostData = async (locale: string, slug: string, params: any) => {
  let data: any = {}, errors: any;
  let t0 = performanceStart();

  // Load post content info
  const postQuery = client.query({
    query: GET_POST,
    variables: {
      language: getLanguageCodeFromNextLocale(locale),
      slug,
    },
  });

  const storeQuery = getStore(locale);

  // Load structural data for header & footer
  const structuralQuery = loadStructuralData(data, locale);

  await Promise.all([
    postQuery,
    storeQuery,
    structuralQuery
  ]).then((values) => {
    const { data: postData, errors: pageErrors } = values[0];
    data = {
      ...data,
      ...postData
    };
    errors = pageErrors;
    performanceLog(t0, `getPostData -> Promise.all()`, {locale, params});
  });

  if (data) {
    // Load additional data required by certain fasce
    const node = data.posts?.nodes?.[0];
    const fasce = node?.template?.fasce;
    t0 = performanceStart();
    await loadDataForFasce(locale, data._structuralData, node, fasce, params);
    performanceLog(t0, `getPostData -> loadDataForFasce()`, {locale, params});
  }

  return { data, errors };
}

export const getCategoryData = async (locale: string, slug: string, params: any) => {
  let data: any = {}, errors: any;
  let t0 = performanceStart();

  // Load post category info
  const categoryQuery = getCategoryRawData(locale, slug);

  const storeQuery = getStore(locale);

  // Load structural data for header & footer
  const structuralQuery = loadStructuralData(data, locale);

  await Promise.all([
    categoryQuery,
    storeQuery,
    structuralQuery
  ]).then((values) => {
    const { data: categoryData, errors: pageErrors } = values[0];
    data = {
      ...data,
      ...categoryData
    };
    errors = pageErrors;
    performanceLog(t0, `getCategoryData -> Promise.all()`, {locale, params});
  });

  if (data) {
    // Load additional data required by certain fasce
    const mainCategoryData = data.categories?.nodes?.[0];
    const fasce = mainCategoryData?.fasce;
    t0 = performanceStart();
    await loadDataForFasce(locale, data._structuralData, mainCategoryData, fasce, params);
    performanceLog(t0, `getCategoryData -> loadDataForFasce()`, {locale, params});
  }

  return { data, errors };
}

export const getPageList = async () => {
  return client.query({
    query: GET_PAGES,
  });
}

export const getPostList = async () => {
  return client.query({
    query: GET_POSTS
  });
}

export const getPostListInCategories = async (categoryIn: number[]) => {
  return client.query({
    query: GET_POSTS_IN_CATEGORIES,
    variables: {
      categoryIn
    }
  });
}

export const getCategoryList = async () => {
  return client.query({
    query: GET_CATEGORIES
  });
}

export const getSearchData = async (locale: string, text: string) => {
  return client.query({
    query: SEARCH,
    variables: {
      language: getLanguageCodeFromNextLocale(locale),
      text,
    },
  });
}
