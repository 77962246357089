
import { gql } from '@apollo/client'

/**
 * Get the list of all post category.
 */
 export const GET_CATEGORIES = gql`
 query GET_CATEGORIES {
   categories(first: 1000) {
     edges {
       node {
         id
         databaseId
         parentId
         name
         slug
         language {
           slug
           code
         }
       }
     }
   }
 }
 `;

/**
 * Get the list of translations of the category having the given slug in the given language.
 */
export const GET_CATEGORY_TRANSLATIONS = gql`
query GET_CATEGORY_TRANSLATIONS( $language: LanguageCodeFilterEnum, $slug: String) {
  categories(where: { language: $language, slug: [$slug] }) {
    edges {
      node {
        id
        databaseId
        parentId
        name
        slug
        language {
          slug
          code
        }
        translations {
          id
          databaseId
          slug
          uri
          language {
            slug
            code
          }
        }
      }
    }
  }
}
`;
