import { WPImage } from "./image";

const FasciaGroupSliderPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_GroupSlider`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      sectionTitle: sectiontitle
      slides {
        fieldGroupName
        backgroundImage: backgroundimage {
          ...ImageFragment
        }
        title
        desc
      }
    }
	`
}

export default FasciaGroupSliderPartial;

export interface WPGroupSliderSlide {
  backgroundImage: WPImage;
  title?: string;
  desc?: string;
}

export interface WPGroupSlider {
  sectionTitle?: string;
  slides: WPGroupSliderSlide[];
}
