import { gql } from "@apollo/client";
import { TaxonomySeoFragment } from "@/data/wp/operations/queries/fragments/seo";
import { FascePartial } from "@/data/wp/operations/queries/fragments/template";
import ImageFragment from "@/data/wp/operations/queries/fragments/image";
import { LanguageFragment, TranslationsPartial } from "@/data/wp/operations/queries/fragments/languages";

export const GET_POST_CATEGORY = gql`
	query GET_POST_CATEGORY($language: LanguageCodeFilterEnum, $slug: String) {
    categories(first: 1000, where: {language: $language, slug: [$slug]}) {
      nodes {
        id
        databaseId
        parentId
        name
        content: description
        slug
        uri
        language {
          ...LanguageFragment
        }
        ${TranslationsPartial}
        translations {
          id
          slug
          language {
            ...LanguageFragment
          }
        }
        children(first: 1000) {
          edges {
            node {
              id
              slug
              name
              parentId
            }
          }
        }
        posts(first: 9000) {
          edges {
            node {
              id
              slug
              title
              excerpt
              fieldsRealizzazioni {
                locality
              }
              fieldsArticoli {
                date
                pdf {
                  id
                  mediaItemUrl
                  title
                  altText
                  caption
                  mimeType
                  date
                  fileSize
                }
                flipbookUrl: flipbookurl
              }
              featuredImage {
                node {
                  id
                  altText
                  mimeType
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                }
              }
              categories {
                edges {
                  node {
                    id
                    slug
                    name
                    parentId
                  }
                }
              }
            }
          }
        }
        seo {
          ...TaxonomySeoFragment
        }
        ${FascePartial('Category')}
      }
    }
	}

  ${LanguageFragment}
  ${TaxonomySeoFragment}
  ${ImageFragment}
`;
