import "react";
import { ParsedUrlQuery } from "querystring";
import { NextRouter, useRouter } from "next/router";

export const SEARCH_QS_PARAM = 'q';

const buildCurrentBrowserPath = (router: NextRouter) => `${router.locale ? '/' + router.locale: ''}${router.asPath}`;

/**
 * Restituisce la browserpath corrente costruita usando NextRouter (es: '/it/p/realizzazioni/post-slug/').
 */
export function useCurrentBrowserPath() {
  const router = useRouter();
  return buildCurrentBrowserPath(router);
}

/**
 * Redirige l'utente alla stessa url ma aggiornando il parametro query string indicato da "pageQSParam" al valore indicato da "page".
 * Da usare in un contesto di paginazione, per portare l'utente ad una certa x pagina.
 *
 * @param router istanza del router Next.js (es: ottenibile con useRouter())
 * @param page numero della pagina a cui portare l'utente (es: 3)
 * @param pageQSParam nome del parametro della query string che rappresenta la pagina corrente (Default: 'page')
 */
export function goToPaginationPageUrl(router: NextRouter, page: number, pageQSParam: string = 'page') {
  // NOTA: usa una baseUrl fake solo per costruire la URL.
  //  Poi viene di fatto usata solo la querystring: url.search
  const baseUrl = 'http://localhost';
  const url = new URL(buildCurrentBrowserPath(router), baseUrl);
  url.searchParams.set(pageQSParam, page.toString());
  router.push(url.pathname + url.search, undefined, { shallow: true });
}

/**
 * Redirige l'utente alla pagina di ricerca con la query indicata dal parametro "text".
 *
 * @param router istanza del router Next.js (es: ottenibile con useRouter())
 * @param string testo da cercare
 */
 export function goToSearchResultPage(router: NextRouter, text: string) {
  // NOTA: usa una baseUrl fake solo per costruire la URL.
  const baseUrl = 'http://localhost';
  const searchPath = buildPageUrl(PageType.SEARCH);
  const url = new URL(searchPath, baseUrl);
  url.searchParams.set(SEARCH_QS_PARAM, text);
  router.push(url.pathname + url.search, undefined, { shallow: true });
}

/**
 * Restituisce l'origin del sito nell'ambiente corrente.
 * Utilizza la variabile di ambiente NEXT_PUBLIC_WEBSITE_URL
 * definita nel file .env per l'ambiente locale che viene sovrascritto durante il deploy su Vercel
 *
 * Locale: "http://localhost:3000"
 * Vercel Preview: "https://disano-uat.vercel.app"
 * Vercel Production: "https://www.disano.it"
 */
export function getOrigin() {
  const currentLocation = process.browser ? window.location.origin : null;
  return process.env.NEXT_PUBLIC_WEBSITE_URL ? process.env.NEXT_PUBLIC_WEBSITE_URL: currentLocation;
}

export enum PageType {
  HOME,
  PAGE,
  POST_CATEGORY,
  POST_DETAIL,
  PDP,
  PLP,
  NOT_FOUND,
  ERROR,
  SEARCH,
}

/**
 * Dato il tipo di contenuto costruisce l'url per raggiungere la rispettiva pagina.
 *
 * @param pageType
 * @param params Parametri da interpolare nell'url (es: { category: 'realizzazioni', slug: 'sligo-park-hotel'})
 * @param locale Se false non prepende il locale (es: 'it' | false)
 * @returns
 */
export function buildPageUrl(pageType: PageType, params?: ParsedUrlQuery, locale?: string) {
  const localeSegment = locale ? `/${locale}` : '';
  switch (pageType) {
    case PageType.PAGE:
      if (params && 'slug' in params) {
        // @ts-ignore
        return `${localeSegment}/${decodeURIComponent(params['slug'])}/`;
      } else {
        return '';
      }

    case PageType.POST_CATEGORY:
      if (params && 'category' in params) {
        // @ts-ignore
        return `${localeSegment}/p/${decodeURIComponent(params['category'])}/`;
      } else {
        return '';
      }

    case PageType.POST_DETAIL:
      if (params && 'category' in params && 'slug' in params) {
        // @ts-ignore
        return `${localeSegment}/p/${decodeURIComponent(params['category'])}/${decodeURIComponent(params['slug'])}/`;
      } else {
        return '';
      }

    case PageType.PLP:
      if (params && 'brand' in params && 'category' in params) {
        // @ts-ignore
        return `${localeSegment}/cat/${decodeURIComponent(params['brand'])}/${decodeURIComponent(params['category'])}`;
      }else{
        return '';
      }

    case PageType.PDP:
      if (params && 'brand' in params && 'category' in params && 'product' in params) {
        // @ts-ignore
        return `${localeSegment}/cat/${decodeURIComponent(params['brand'])}/${decodeURIComponent(params['category'])}/${decodeURIComponent(params['product'])}`;
      }else{
        return '';
      }

    case PageType.SEARCH:
      return `${localeSegment}/search/`;

    case PageType.HOME:
    default:
      return `${localeSegment}/`;
  }
}
