
import { gql } from '@apollo/client'

/**
 * Get HCL commerce category details by categoryId .
 */
 export const GET_STOREID_BY_STORENAME = gql`
 query GET_STOREID_BY_STORENAME( $storeId: String!, $directory: String!) {
    storeFindByStoreNameAndUsage(storeId: $storeId, storeName:"", storeIdentifier: $directory, responseFormat: JSON, q: FINDBYSTOREIDENTIFIER, usage: ""){
       resultList{
            defaultCatalogId
            defaultCurrency
            defaultLanguageId
            storeId
            inventorySystem
       }
   }
 }
 `;