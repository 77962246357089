const FasciaFullContentPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_FullContent`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
    }
	`
}

export default FasciaFullContentPartial;

export interface WPFullContent {}
