import client from "@/data/hcl/apollo/client";
import { GET_PRODUCTS_BY_ESPOT } from "@/data/hcl/operations/queries/get-products-by-espot-name";
import { GET_PRODUCT_BY_ID } from "@/data/hcl/operations/queries/get-product-by-id";
import { GET_TOP_CATEGORIES } from "../operations/queries/get-top-categories";
import { GET_SUB_CATEGORIES } from "../operations/queries/get-sub-cateogries";
import { GET_PRODUCT_BY_CATEGORY_ID, GET_PRODUCT_BY_CATEGORY_ID_AND_FILTERED_VALUE } from "../operations/queries/get-product-by-category-id";
import { GET_PRODUCT_BY_PARTNUMBERS } from "../operations/queries/get-product-by-partnumbers";
import { GET_CATEGORY_BY_ID } from "../operations/queries/get-category-by-id";
import { GET_STOREID_BY_STORENAME } from "../operations/queries/get-store-id-by-store-name";
import { GET_PRODUCTS_BY_SEARCH_TERM } from "../operations/queries/get-products-by-search-term";
import { GET_SKU_BY_SEARCH_TERM } from "../operations/queries/get-sku-by-search-term";
import { GET_PRODUCT_BY_PARTNUMBER } from "../operations/queries/get-product-by-partnumber";

export const getStoreDataByDirectory = async (storeId: string | undefined, directory: string) => {
  return client.query({
    query: GET_STOREID_BY_STORENAME,
    variables: {
      storeId: storeId,
      directory: directory
    }
  });
}

export const getProductsByEspotName = async (storeId: string, langId: string, espotName: string) => {
  return client.query({
    query: GET_PRODUCTS_BY_ESPOT,
    variables: {
      storeId: storeId,
      langId: langId,
      name: espotName
    }
  });
}

export const getProductById = async (storeId: string, langId: string, productId: string) => {
  return client.query({
    query: GET_PRODUCT_BY_ID,
    variables: {
      storeId: storeId,
      langId: langId,
      productId: productId
    }
  });
}

/**
 *  Note: partnumbers must be encoded as Hex Numbers (eg: "prod_351440" -> "70726f645f333531343430")
 *    since the HCL search index has been customized to prevent the search result to show results for "351440"
 */
export const getProductByPartNumbers = async (storeId: string, langId: string, partNumber: Array<string>) => {
  return client.query({
    query: GET_PRODUCT_BY_PARTNUMBERS,
    variables: {
      storeId: storeId,
      langId: langId,
      partNumber: partNumber
    }
  });
}

export const getTopCategories = async (storeId: string) => {
  return client.query({
    query: GET_TOP_CATEGORIES,
    variables: {
      storeId: storeId
    }
  });
}

export const getSubCategoriesById = async (storeId: string, parentCategoryId: string) => {
  return client.query({
    query: GET_SUB_CATEGORIES,
    variables: {
      storeId: storeId,
      parentCategoryId: parentCategoryId
    }
  });
}

export const getProductByCategoryId = async (queryParams: any) => {
let variables : {[key: string]: any} = {
    storeId: queryParams.storeId,
    langId: queryParams.langId,
    categoryId: queryParams.categoryId
  };
  if(queryParams.orderBy) {
    variables.orderBy = queryParams.orderBy;
  }
  if(queryParams.pageNumber) {
    variables.pageNumber = queryParams.pageNumber;
  }
  if(queryParams.pageSize) {
    variables.pageSize = queryParams.pageSize;
  }
  return client.query({
    query: GET_PRODUCT_BY_CATEGORY_ID,
    variables: variables
  });
}

export const getProductByCategoryIdAndFilteredValue = async (storeId: string, langId: string, categoryId: string, filteredValue : string, orderBy: string, pageNumber: string, pageSize: string) => {
  let variablesFilter : {[key: string]: any} = {
    storeId: storeId,
    langId: langId,
    categoryId: categoryId,
    facet: filteredValue
  };
  if(orderBy) {
    variablesFilter.orderBy = orderBy;
  }
  if(pageNumber) {
    variablesFilter.pageNumber = pageNumber;
  }
  if(pageSize) {
    variablesFilter.pageSize = pageSize;
  }
  return client.query({
    query: GET_PRODUCT_BY_CATEGORY_ID_AND_FILTERED_VALUE,
    variables: variablesFilter
  });
}

export const getCategoryDataById = async (storeId: string, langId: string, categoryId: string) => {
  return client.query({
    query: GET_CATEGORY_BY_ID,
    variables: {
      storeId: storeId,
      langId: langId,
      categoryId: categoryId
    }
  });
}

export const getProductByPartNumber = async (storeId: string, langId: string, partNumber: string) => {
  return client.query({
    query: GET_PRODUCT_BY_PARTNUMBER,
    variables: {
      storeId: storeId,
      langId: langId,
      partNumber: partNumber
    }
  });
}

export const getProductBySearchTerm = async (storeId: string, langId: string, searchTerm: string) => {
  return client.query({
    query: GET_PRODUCTS_BY_SEARCH_TERM,
    variables: {
      storeId: storeId,
      langId: langId,
      searchTerm: searchTerm
    }
  });
}

export const getSkuBySearchTerm = async (storeId: string, langId: string, searchType: string, searchTerm: string) => {
  return client.query({
    query: GET_SKU_BY_SEARCH_TERM,
    variables: {
      storeId: storeId,
      langId: langId,
      searchType: searchType,
      searchTerm: searchTerm
    }
  });
}
