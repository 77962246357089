
import { gql } from '@apollo/client'

/**
 * Get product details by productId.
 */
 export const GET_PRODUCT_BY_ID = gql`
 query GET_PRODUCT_BY_ID( $storeId: String!, $langId: String, $productId: String!) {
        productViewFindProductById(storeId: $storeId, langId: $langId, productId: $productId){
            catalogEntryView {
                name
                partNumber
                shortDescription
                longDescription
                uniqueID
                fullImage
                thumbnail
                parentCatalogGroupID
                parentCatalogEntryID
                merchandisingAssociations {
                    attributes {
                        name
                        identifier
                        values {
                            value
                        }
                    }
                    associationType
                    fullImage
                    name
                    partNumber
                    shortDescription
                    uniqueID
                    thumbnail
                    parentCatalogGroupID
                }
                attributes {
                    name
                    identifier
                    usage
                    values {
                        identifier
                        image1
                        uniqueID
                        value
                    }
                }
                attachments {
                    attachmentAssetPath
                    usage
                    identifier
                    longdesc
                    name
                }
                sKUs{
                    name
                    partNumber
                    uniqueID
                    attributes {
                        name
                    identifier
                    usage
                    values {
                        identifier
                        image1
                        uniqueID
                        value
                    }
                }
            }
        }
    }
 }
 `;