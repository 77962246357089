
const FasciaTopAnchorNavPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_TopAnchorNav`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      items {
        label
        sectionId: sectionid
      }
    }
	`
}

export default FasciaTopAnchorNavPartial;

export interface WPTopAnchorNav {
  items: {
    label: string,
    sectionId: string,
  }[];
}
