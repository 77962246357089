const FasciaProductListPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_ProductList`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      products {
        id
      }
      title
      grayBackground: graybackground
      viewAllUrl: viewallurl
      viewAllLabel: viewalllabel
      layout3Col: layout3col
    }
  `
}

export default FasciaProductListPartial;

export interface WPProductList {
  products: { id: string}[]
  title?: string
  grayBackground?: boolean
  viewAllUrl?: string
  viewAllLabel?: string
  layout3Col?: boolean
}
