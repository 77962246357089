
import { gql } from '@apollo/client'

/**
 * Get product details by productId.
 */
 export const GET_SUB_CATEGORIES = gql`
 query GET_SUB_CATEGORIES( $storeId: String!, $parentCategoryId: String!) {
   categoryViewFindSubCategories(storeId: $storeId, parentCategoryId: $parentCategoryId){
        catalogGroupView {
            name
            shortDescription
            uniqueID
            identifier
        }
    }
 }
 `;