
import { gql } from '@apollo/client'

/**
 * Get product details by productId.
 */
export const GET_PRODUCT_BY_PARTNUMBERS = gql`
query GET_PRODUCT_BY_PARTNUMBERS( $storeId: String!, $langId: String, $partNumber: [String]!) {
  productViewFindProductByPartNumbers(storeId: $storeId, langId: $langId, partNumber: $partNumber){
    catalogEntryView {
                name
                shortDescription
                partNumber
                uniqueID
                fullImage
                thumbnail
                parentCatalogEntryID
                parentCatalogGroupID
                attributes {
                    identifier
                    uniqueID
                    usage
                    values {
                        value
                    }
                }
                attachments {
                identifier
                mimeType
                attachmentAssetPath
                usage
                catentryId
            }
        }
    }
 }
 `;