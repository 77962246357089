
const VideoPartial = `
  type
  title
  videourl
  width
  height
`

export default VideoPartial;

export interface WPVideo {
  type: string
  videourl: string
  width: number
  height: number
  title: string
}
