const FasciaMissionPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_Mission`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      titleSection: titlesection
      columns {
        icon
        title
        desc
      }
    }
	`
}

export default FasciaMissionPartial;

export interface WPMissionIcon {
  icon?: string;
  title?: string;
  desc?: string;
}

export interface WPMission {
  titleSection?: string;
  columns: WPMissionIcon[];
}
