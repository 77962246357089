
import { gql } from '@apollo/client'

/**
 * Get the list of of all pages (base info for getStaticPaths) *
 */
export const GET_PAGES = gql`
 query GET_PAGES {
  pages: pages(first: 9000) {
    nodes {
      id
      slug
      uri
      isFrontPage
      language {
        slug
      }
    }
  }
 }
`;
