
import { gql } from '@apollo/client'

/**
 * Get product details by productId.
 */
export const GET_PRODUCT_BY_PARTNUMBER = gql`
query GET_PRODUCT_BY_PARTNUMBER( $storeId: String!, $langId: String!, $partNumber: String!) {
    productViewFindProductByPartNumber(storeId: $storeId, langId: $langId, partNumber: $partNumber){
        catalogEntryView {
                  name
                  partNumber
                  longDescription
                  shortDescription
                  uniqueID
                  thumbnail
                  fullImage
                  parentCatalogEntryID
                  parentCatalogGroupID
                  attributes {
                    name
                    identifier
                    usage
                    values {
                        identifier
                        image1
                        uniqueID
                        value
                    }
                  }
                  attachments {
                    name
                    attachmentAssetPath
                    usage
                    identifier
                    mimeType
                    longdesc
                }
              }
        }
    }
 `;