const FasciaColumnTextPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_ColumnText`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      columns {
        fieldGroupName
        title
        desc
      }
    }
	`
}

export default FasciaColumnTextPartial;

export interface WPColumnTextItem {
  title?: string;
  desc?: string;
}

export interface WPColumnText {
  columns: WPColumnTextItem[];
}
