
import { gql } from '@apollo/client'

/**
 * Get the list of all post category.
 */
 export const GET_PRODUCTS_BY_ESPOT = gql`
 query GET_PRODUCTS_BY_ESPOT( $storeId: String!, $langId: String, $name: String) {
   eSpotFindByName(storeId: $storeId, langId: $langId, name: $name){
        marketingSpotData {
            marketingSpotDataTitle {
                contentName
            }
            baseMarketingSpotActivityData {
                productId
                productPartNumber
            }
        }
    }
 }
 `;