const FasciaSidedContentPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_SidedContent`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      sideMenus: sidemenus {
        navMenuLocation: navmenulocation
      }
    }
	`
}

export default FasciaSidedContentPartial;

export interface WPSidedContent {
  sideMenus?: {
    navMenuLocation: string
  }[];
}
