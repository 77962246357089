import { gql } from "@apollo/client";

export const SEARCH = gql`
  query SEARCH($language: LanguageCodeFilterEnum, $text: String) {
    pages(last: 1000, where: { language: $language, search: $text }) {
      edges {
        node {
          contentTypeName
          id
          slug
          title
          content
          featuredImage {
            node {
              id
              altText
              mimeType
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
          }
        }
      }
    }

    posts(last: 1000, where: { language: $language, search: $text }) {
      edges {
        node {
          contentTypeName
          id
          slug
          title
          excerpt
          fieldsRealizzazioni {
            locality
            info {
              label
              value
            }
          }
          fieldsArticoli {
            date
            pdf {
              id
              mediaItemUrl
              title
              altText
              caption
              mimeType
              date
              fileSize
            }
            flipbookUrl: flipbookurl
          }
          featuredImage {
            node {
              id
              altText
              mimeType
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
          }
          categories {
            edges {
              node {
                id
                slug
                name
                parentId
              }
            }
          }
        }
      }
    }
  }
`;
