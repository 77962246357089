
import { gql } from '@apollo/client'

/**
 * Get product details by productId.
 */
 export const GET_TOP_CATEGORIES = gql`
 query GET_TOP_CATEGORIES( $storeId: String!) {
   categoryViewFindTopCategories(storeId: $storeId){
        catalogGroupView {
            name
            uniqueID
            fullImage
            identifier
            shortDescription
            childCatalogGroupID
            identifier
        }
    }
 }
 `;