
import DOMPurify from 'isomorphic-dompurify';
import { slugify } from 'transliteration';

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML.
 *
 * Moreover it remove any \n and \r, replace \t with a space
 * (useful if the string comes from a CMS rich text editor).
 *
 * @param {string} content Plain or html string.
 * @param {boolean} removeScripts
 *
 * @return {string} Sanitized string
 */
export const sanitize = (content: string, removeScripts: boolean = true) => {
  const text = removeScripts ? DOMPurify.sanitize(content): content;
	return text && text.replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\t/g, ' ');
};

/**
 * Strip HTML Tags in JavaScript.
 * Note: it does not remove comments.
 *
 * Source: https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
 *
 * @param content html string content
 * @returns plain text without html tags
 */
export const stripHtml = (content: string) => {
  return content ? content.replace(/(<([^>]+)>)/gi, ''): '';
}

/**
 * Strips html tags from the content string and truncate it if longer than maxLength,
 * appending "ellipses" if necessary.
 *
 * @param content html string content
 * @param maxLength (default: 190)
 * @param ellipses (default: '...')
 * @returns
 */
export const excerptHtml = (content: string, maxLength: number = 190, ellipses = '...') => {
  let stripedContent = stripHtml(content);
  if (stripedContent.length > maxLength) {
    stripedContent = stripedContent.substring(0, maxLength) + ellipses;
  }
  return stripedContent;
}


/**
 * Generate an id from the given free text string by removing all non letter/number character
 * See https://stackoverflow.com/questions/25317997/function-to-create-a-valid-id-from-string-regular-expression
 */
export const idFromString = (text: string): string => {
  return slugify(text);
}

/**
 * Convert the given string to the corresponding Hex Number.
 * See: https://string-functions.com/string-hex.aspx
 * See: https://stackoverflow.com/questions/21647928/javascript-unicode-string-to-hex
 */
export const toHex = function(str: string): string {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}
