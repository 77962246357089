import FasciaHeroPartial from "./fascia-hero-banner";
import FasciaStandoutBannerPartial from "./fascia-standout-banner";
import FasciaWideBannersPartial from "./fascia-wide-banners";
import FasciaRealizzazioniListPartial from "./fascia-realizzazioni-list";
import FasciaNewsFiereEventiListPartial from "./fascia-news-list";
import FasciaProductListPartial from "./fascia-product-list";
import FasciaFullBannerPartial from "./fascia-full-banner";
import FasciaColumnTextPartial from "./fascia-column-text";
import FasciaGroupSliderPartial from "./fascia-group-slider";
import FasciaFullDoubleColumnPartial from "./fascia-full-double-column";
import FasciaCenteredTextIconPartial from "./fascia-centered-text-icon";
import FasciaMissionPartial from "./fascia-mission";
import FasciaNormativePartial from "./fascia-normative";
import FasciaShowroomListPartial from "./fascia-showroom-list";
import FasciaTopAnchorNavPartial from "./fascia-top-anchor-nav";
import FasciaSidedContentPartial from "./fascia-sided-content";
import FasciaFullContentPartial from "./fascia-full-content";
import FasciaContactsPartial from "./fascia-contacts";
import FasciaPostListPartial from "./fascia-post-list";
import FasciaSearchHeadingPartial from "./fascia-search-heading";
import FasciaSpacerPartial from "./fascia-spacer";
import FasciaBrandCategoriesPartial from "./fascia-brand-categories";

export const FascePartial = (baseType: string = 'DefaultTemplate') => {
  const fasceBaseType = `${baseType}_Fasce_Fasce`;
  return `
    fasce {
      fasce {
        ${FasciaHeroPartial(fasceBaseType)}
        ${FasciaStandoutBannerPartial(fasceBaseType)}
        ${FasciaWideBannersPartial(fasceBaseType)}
        ${FasciaRealizzazioniListPartial(fasceBaseType)}
        ${FasciaNewsFiereEventiListPartial(fasceBaseType)}
        ${FasciaProductListPartial(fasceBaseType)}
        ${FasciaFullBannerPartial(fasceBaseType)}
        ${FasciaColumnTextPartial(fasceBaseType)}
        ${FasciaGroupSliderPartial(fasceBaseType)}
        ${FasciaFullDoubleColumnPartial(fasceBaseType)}
        ${FasciaCenteredTextIconPartial(fasceBaseType)}
        ${FasciaMissionPartial(fasceBaseType)}
        ${FasciaNormativePartial(fasceBaseType)}
        ${FasciaShowroomListPartial(fasceBaseType)}
        ${FasciaTopAnchorNavPartial(fasceBaseType)}
        ${FasciaSidedContentPartial(fasceBaseType)}
        ${FasciaFullContentPartial(fasceBaseType)}
        ${FasciaPostListPartial(fasceBaseType)}
        ${FasciaSearchHeadingPartial(fasceBaseType)}
        ${FasciaContactsPartial(fasceBaseType)}
        ${FasciaSpacerPartial(fasceBaseType)}
        ${FasciaBrandCategoriesPartial(fasceBaseType)}
      }
      fieldGroupName
    }
  `
}

export const TemplateFragment = `
	fragment TemplateFragment on ContentTemplate {
    templateName
    ... on DefaultTemplate {
      templateName
      ${FascePartial('DefaultTemplate')}
    }
	}
`
