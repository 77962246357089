const FasciaCenteredTextIconPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_CenteredTextIcon`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      title
      subtitle
      desc
      smallMarginTitle: smallmargintitle
      icons {
        icon
        desc
      }
    }
	`
}

export default FasciaCenteredTextIconPartial;

export interface WPCenteredTextIconIcon {
  icon?: string;
  desc?: string;
}

export interface WPCenteredTextIcon {
  title?: string;
  subtitle?: string;
  desc?: string;
  smallMarginTitle?: boolean;
  icons?: WPCenteredTextIconIcon[]
}

