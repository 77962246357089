
import { gql } from '@apollo/client'

/**
 * Get sku details by searchTerm.
 */
 export const GET_SKU_BY_SEARCH_TERM = gql`
 query GET_SKU_BY_SEARCH_TERM($storeId: String!, $langId: String!, $searchType: String!, $searchTerm: String!) {
  productViewFindProductsBySearchTerm(storeId: $storeId, langId: $langId, searchType: $searchType, searchTerm: $searchTerm) {
    recordSetStartNumber
    recordSetCount
    recordSetTotal
    recordSetTotalMatches
    facetView {
        name
        value
        extendedData {
            srchattridentifier
        }
        entry {
            count
            label
            value
        }
    }
    catalogEntryView {
        name
        partNumber
        uniqueID
        thumbnail
        parentCatalogGroupID
        attributes {
            identifier
            uniqueID
            usage
            values {
                value
            }
        }
    }
  }
 }
 `;
