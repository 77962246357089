const FasciaPostListPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_PostList`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      category {
        id
        databaseId
        name
        slug
      }
      showCategoryFilters: showcategoryfilters
      itemsPerRow: itemsperrow
      preventAccessToNonItUsers: preventaccesstononitusers
    }
  `
}

export default FasciaPostListPartial;

export type WPPostListItemsPerRow = '2' | '3' | '4';

export interface WPPostList {
  category: {
    id: string
    databaseId: number
    name: string
    slug: string
  }
  showCategoryFilters?: boolean
  itemsPerRow: WPPostListItemsPerRow
  preventAccessToNonItUsers?: boolean
}
