const FasciaContactsPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_Contacts`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      title
      desc
      map {
        showMap: showmap
        title
        center {
          latitude
          longitude
          zoom
        }
      }
      regions {
        fieldGroupName
        title
        subtitle
        desc
        markers {
          title
          coordinates {
            latitude
            longitude
            zoom
          }
        }
      }
      sideMenus: sidemenus {
        navMenuLocation: navmenulocation
      }
    }
	`
}

export default FasciaContactsPartial;

export interface WPContactsMap {
  showMap: boolean
  title?: string
  center: {
    latitude: number
    longitude: number
    zoom: string
  }
}

export interface WPContactsRegionMarker {
  title?: string
  coordinates: {
    latitude: number
    longitude: number
    zoom: string
  }
}

export interface WPContactsRegion {
  title?: string
  subtitle?: string
  desc?: string
  markers?: WPContactsRegionMarker[]
}

export interface WPContacts {
  title?: string
  desc?: string
  map: WPContactsMap
  regions: WPContactsRegion[]
  sideMenus?: {
    navMenuLocation: string
  }[]
}
