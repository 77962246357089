import isEmpty from "lodash/isEmpty";
import { GetServerSideProps, GetStaticPaths, GetStaticPathsResult, GetStaticProps } from "next";
import { ServerResponse } from "http";

import { getCategoryData, getCategoryList, getPageData, getPageList, getPostData, getPostList } from "@/data/wp/sdk";
import { getProductDataByCategoryId, getProductDataById, mapProductItemToCardList } from "@/data/hcl/sdk/utils";
import { getStore, storeId, catalogId, langId, currency } from "./storeInfo";
import getT from 'next-translate/getT';
import absoluteUrl from "next-absolute-url";
import { performanceLog, performanceStart } from "@/utils/performance";
import { loadStructuralData } from "@/data/wp/sdk/utils";
import { getProductById, getProductByPartNumber } from "@/data/hcl/sdk";

export interface ServerSideParams {
  storeId: any;
  catalogId: any;
  langId: any;
  currency: any;
  productId: any;
  orderBy: any;
  categoryId: any;
  params: any;
  hostname: any;
  locale: any;
  translate: any;
}

/*
  See: https://nextjs.org/docs/api-reference/data-fetching/get-static-paths

  - fallback: false
      It will then build only the paths returned by getStaticPaths,
      while any other paths will result in a 404 page.
  - fallback: true
      For paths that have not been generated at build time, on the first request,
      it will serve a “fallback” version of the page (to be implented, es with loader).
      In the background, Next.js will statically generate the requested path, and it will swap and show it to the user when is ready.
  - fallback: 'blocking' (preferred for SEO)
      New paths not returned by getStaticPaths will wait for the HTML to be generated (identical to SSR),
      and then be cached for future requests so it only happens once per path.
*/
export const FALLBACK = 'blocking';

export const isCustomPageUri = (uri: string) => {
	const pagesToExclude = [
		'/',
		// '/about'
		// '/other-custom-page'
	];

	return pagesToExclude.includes(uri);
};

/**
 * Given a param from next.js getStaticProps,
 * return the (single) string value to query WP.
 *
 * For example, in case a "slug" param is passed, and the url was composed by multiple sections,
 * it returns the last one, that should refer to the post itself.
 */
export const getLastParamString = (param: string | string[] | undefined): string => {
  let value = '';
  if (param) {
    if (typeof param === 'string') {
      value = param;
    } else if (Array.isArray(param) && param.length > 0) {
      value = param[param.length - 1];
    }
  }
  return value;
}

/**
 * Check if the expected data (specified by "field") was loaded from the CMS,
 * redirecting to /500, /404 when needed.
 * It also redirects to login page if preview mode is enabled but the user is not logged (WIP).
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching
 */
export const handleRedirectsAndReturnData = (defaultProps: any, data: any, errors: any, field?: string, isPreview = false, loginRedirectURL = '') => {
	if (errors) {
		console.error(`\nhandleRedirectsAndReturnData - an error occurred: `, errors);
    throw {
      message: 'handleRedirectsAndReturnData - an error occurred',
      details: errors
    }
	}

	if (isPreview && field && null === data?.[field]) {
		console.log(`\nhandleRedirectsAndReturnData - preview mode but missing data field (${field}): redirecting to login page`);
		return {
			redirect: {
				destination: loginRedirectURL || '/',
				statusCode: 307
			}
		};
	}

	if (isEmpty(data)) {
		console.log(`\nhandleRedirectsAndReturnData - empty data object`);
    throw {
      message: 'nhandleRedirectsAndReturnData - empty data object'
    }
	}

	if (field && (isEmpty(data?.[field]?.nodes))) {
		console.log(`\nhandleRedirectsAndReturnData - missing data field (${field}): not found`);
		return {
			// returns the default 404 page with a status code of 404
			notFound: true
		};
	}

  if (data?._products && (isEmpty(data?._products))) {
		console.log(`\nhandleRedirectsAndReturnData - missing category data not found`);
		return {
			// returns the default 404 page with a status code of 404
			notFound: true
		};
	}

  if (data?.product && (isEmpty(data?.product))) {
		console.log(`\nhandleRedirectsAndReturnData - missing product data not found`);
		return {
			// returns the default 404 page with a status code of 404
			notFound: true
		};
	}

	return defaultProps;
};


/**
 * Set the response header to make this url cachable.
 * This method should be used within a getServerSideProps function (Next.js SSR).
 * For static pages (eg: getStaticProps) you should use the ISR "revalidate" property instead.
 *
 * This value is considered fresh for NEXT_PUBLIC_SSR_MAXAGE seconds (eg: 300 = 60 seconds * 5 minutes).
 * If a request is repeated within the next NEXT_PUBLIC_SSR_MAXAGE seconds, the previously
 * cached value will still be fresh.
 *
 * For requests coming after NEXT_PUBLIC_SSR_MAXAGE seconds,
 * the cached value will be stale but still render (stale-while-revalidate).
 * In the background, a revalidation request will be made to populate the cache
 * with a fresh value. The following requests will receive the new fresh value.
 *
 * Note: an empty stale-while-revalidate is equivalent to setting it to an infinte value.
 * That is to say that, after NEXT_PUBLIC_SSR_MAXAGE seconds, the value will allways be valid, even if stale.
 * This allows to prevent blocking requests from the client.
 *
 * @param res the response object  passed by Next.js SSR in getServerSideProps.
 * @see https://nextjs.org/docs/pages/building-your-application/deploying/production-checklist#caching
 */
export const setResponseCacheControl = (res: ServerResponse) => {
  res.setHeader(
    'Cache-Control',
    `public, s-maxage=${process.env.NEXT_PUBLIC_SSR_MAXAGE || '300'}, stale-while-revalidate`
  )
}

/*************************** GET STATIC PROPS ***************************/

/**
 * Loads the minimum layout data (i.e: Header & Footer only) for pages without CMS / Catalog content (eg: error pages),
 * and return the object as expected from Next.js getStaticProps, to implement Static Site Generation.
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 */
 export const getBaseStaticProps: GetStaticProps = async ({ locale }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false') {
    return { props: { data: {} } };
  } else {
    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';
    const data: any = {};

    // Load structural data for header & footer
    const t0 = performanceStart();
    await loadStructuralData(data, nuLocale);
    performanceLog(t0, `getBaseStaticProps -> loadStructuralData()`, { locale });

    return {
      props: {
        data: data || {}
      },
      /**
       * Incremental static regeneration (see: https://vercel.com/docs/concepts/next.js/incremental-static-regeneration):
       * Revalidate means that if a new request comes to server, then every 1 sec it will check
       * if the data is changed, if it is changed then it will update the
       * static file inside .next folder with the new data, so that any 'SUBSEQUENT' requests should have updated data.
       */
       revalidate: parseInt(process.env.NEXT_PUBLIC_ISR_REVALIDATE_BASE || '1'),
    };
  }
}

/**
 * Loads the layout ang CMS Page data (i.e: Header, Footer, Page content etc) for pages with CMS content,
 * and return the object as expected from Next.js getStaticProps, to implement Static Site Generation.
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 */
export const getPageStaticProps: GetStaticProps = async ({ locale, params }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false') {
    return { props: { data: {} } };
  } else {
    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    let slug = '';
    if (!params?.slug || params?.slug.length === 0) {
      slug = 'homepage';
    } else {
      slug = getLastParamString(params?.slug);
    }

    const t0 = performanceStart();
    const { data, errors } = await getPageData(nuLocale, slug, params);
    performanceLog(t0, `getPageStaticProps -> getPageData()`, {locale, params});

    const defaultProps = {
      props: {
        data: data || {},
      },
      /**
       * Incremental static regeneration (see: https://vercel.com/docs/concepts/next.js/incremental-static-regeneration):
       * Revalidate means that if a new request comes to server, then every 1 sec it will check
       * if the data is changed, if it is changed then it will update the
       * static file inside .next folder with the new data, so that any 'SUBSEQUENT' requests should have updated data.
       */
      revalidate: parseInt(process.env.NEXT_PUBLIC_ISR_REVALIDATE_PAGE || '1'),
    };

    return handleRedirectsAndReturnData(defaultProps, data, errors, 'pages');
  }
}

/**
 * Loads the layout ang CMS Page data (i.e: Header, Footer, Page content etc) for the Catalog Brand Page (eg: /it/cat/disano/) with CMS content,
 * and return the object as expected from Next.js getStaticProps, to implement Static Site Generation.
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 */
 export const getStaticPropsForCatBrand: GetStaticProps = async ({ locale, params }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false') {
    return { props: { data: {} } };
  } else {
    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    let slug = '';
    if (!params?.brand || params?.brand.length === 0) {
      slug = 'disano';
    } else {
      slug = getLastParamString(params?.brand);
    }

    const t0 = performanceStart();
    const { data, errors } = await getPageData(nuLocale, slug, params);
    performanceLog(t0, `getStaticPropsForCatBrand -> getPageData()`, {locale, params});

    const defaultProps = {
      props: {
        data: data || {},
      },
      /**
       * Incremental static regeneration (see: https://vercel.com/docs/concepts/next.js/incremental-static-regeneration):
       * Revalidate means that if a new request comes to server, then every 1 sec it will check
       * if the data is changed, if it is changed then it will update the
       * static file inside .next folder with the new data, so that any 'SUBSEQUENT' requests should have updated data.
       */
      revalidate: parseInt(process.env.NEXT_PUBLIC_ISR_REVALIDATE_PAGE || '1'),
    };

    return handleRedirectsAndReturnData(defaultProps, data, errors, 'pages');
  }
}

/**
 * Loads the layout ang CMS data (i.e: Header, Footer, page content etc) for POST with CMS content,
 * and return the object as expected from Next.js getStaticProps, to implement Static Site Generation.
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 */
 export const getStaticPropsForPostDetail: GetStaticProps = async ({ locale, params }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false') {
    return {
      props: { data: {} }
    };
  } else {
    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    let slug = '';
    if (params?.slug) {
      slug = getLastParamString(params?.slug);
    }

    const t0 = performanceStart();
    const { data, errors } = await getPostData(nuLocale, slug, params);
    performanceLog(t0, `getStaticPropsForPostDetail -> getPostData()`, {locale, params});

    const defaultProps = {
      props: {
        data: data || {},
      },
      revalidate: parseInt(process.env.NEXT_PUBLIC_ISR_REVALIDATE_POST_DETAIL || '1'),
    };

    const postNode = data?.posts?.nodes?.[0];

    // Restituisco 404 se la categoria indicata nell’url non corrisponde alla categoria principale effettivamente assegnata al post
    const rootCategory = postNode?.categories?.edges?.find((cat: any) => cat?.node?.parentId === null)?.node;
    if (rootCategory?.slug && params?.category && decodeURIComponent(getLastParamString(rootCategory?.slug)) !== decodeURIComponent(getLastParamString(params?.category))) {
      return { notFound: true };
    }

    // Se il post ha un allegato o il link di uno sfogliabile
    // assumo che non debba avere una pagina di dettaglio,
    // ma che debba essere solo elencato nella pagina di categoria,
    // quindi restituisco 404
    if (postNode?.fieldsArticoli?.pdf !== null || postNode?.fieldsArticoli?.flipbookUrl !== null) {
      return { notFound: true };
    }

    return handleRedirectsAndReturnData(defaultProps, data, errors, 'posts');
  }
}


/**
 * Loads the layout ang CMS data (i.e: Header, Footer, page content etc) for a Post Category with CMS content,
 * and return the object as expected from Next.js getStaticProps, to implement Static Site Generation.
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 */
 export const getStaticPropsForPostCategory: GetStaticProps = async ({ locale, params }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false') {
    return { props: { data: {} } };
  } else {
    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    let categorySlug = '';
    if (params?.category) {
      categorySlug = getLastParamString(params?.category);
    }

    const t0 = performanceStart();
    const { data, errors } = await getCategoryData(nuLocale, categorySlug, params);
    performanceLog(t0, `getStaticPropsForPostCategory -> getCategoryData()`, {locale, params});

    const defaultProps = {
      props: {
        data: data || {},
      },
      revalidate: parseInt(process.env.NEXT_PUBLIC_ISR_REVALIDATE_POST_CATEGORY || '1'),
    };

    // Restituisco 404 se non è una root category (le sotto categorie non devono avere una pagina nel sito)
    if (data?.categories?.nodes?.[0]?.parentId !== null) {
      return { notFound: true };
    }

    return handleRedirectsAndReturnData(defaultProps, data, errors, 'categories');
  }
}

/*************************** GET SERVER SIDE PROPS ***************************/

/**
 * Load products data by cateogryId from HCL Commerce
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props
 */
export const getServerSidePropsForProductByCategoryId: GetServerSideProps = async ({ res, req, locale, params }) => {
    let data: any = {}, errors: any;

    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    await getStore(nuLocale);

    let categoryIdPath = '';
    let categoryId = '';
    if (params?.category) {
      categoryIdPath = getLastParamString(params?.category);
      categoryId = categoryIdPath?.split('-')[0];
    }
    //get translations for server side props
    const t = await getT(nuLocale, 'common');

    const { origin } = absoluteUrl(req);

    let dataParams : ServerSideParams = {
      storeId: storeId,
      catalogId: catalogId,
      langId: langId,
      currency: currency,
      productId: "",
      orderBy: getDefaultSortOrder(),
      categoryId: categoryId,
      locale: nuLocale,
      params: params,
      hostname: origin,
      translate: t
    };

    const t0 = performanceStart();

    // Load category info
    const categoryQuery = getProductDataByCategoryId(dataParams);

    // Load structural data for header & footer
    const structuralQuery = loadStructuralData(data, nuLocale);

    await Promise.all([
      categoryQuery,
      structuralQuery
    ]).then((values) => {
      const { data: categoryData } = values[0];
      data = {
        ...data,
        ...categoryData
      };
      ({ errors } = values[1]);
      performanceLog(t0, `getServerSidePropsForProductByCategoryId -> Promise.all()`, dataParams);
    });

    const defaultProps = {
      props: {
        data: data || {},
      }
    };
    setResponseCacheControl(res);
    return handleRedirectsAndReturnData(defaultProps, data, errors);
}


export const getDefaultSortOrder = function () {
  return '2';
}

/**
 * Load product details data by productId from HCL Commerce
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props
 */
export const getServerSidePropsForProductById: GetServerSideProps = async ({ res, req, locale, params }) => {
    let data: any = {}, errors: any;

    const nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

    await getStore(nuLocale);

    let productIdPath = '';
    let productId = '';
    let categoryIdPath = '';
    let categoryId = '';
    if (params?.category) {
      categoryIdPath = getLastParamString(params?.category);
      categoryId = categoryIdPath?.split('-')[0];
    }
    if (params?.product) {
      productIdPath = getLastParamString(params?.product);
      productId = productIdPath?.split('-')[0];
    }
    //get translations for server side props
    const t = await getT(nuLocale, 'common');

    const { origin } = absoluteUrl(req);

    let dataParams : ServerSideParams = {
      storeId: storeId,
      catalogId: catalogId,
      langId: langId,
      currency: currency,
      productId: productId,
      orderBy: getDefaultSortOrder(),
      categoryId: categoryId,
      locale: nuLocale,
      params: params,
      hostname: origin,
      translate: t
    };

    const t0 = performanceStart();

    // Load product info
    const productQuery = getProductDataById(dataParams);

    // Load structural data for header & footer
    const structuralQuery = loadStructuralData(data, nuLocale);

    await Promise.all([
      productQuery,
      structuralQuery
    ]).then((values) => {
      const { data: productData } = values[0];
      data = {
        ...data,
        ...productData
      };
      ({ errors } = values[1]);
      performanceLog(t0, `getServerSidePropsForProductById -> Promise.all()`, dataParams);
    });

    const defaultProps = {
      props: {
        data: data || {},
      }
    };
    setResponseCacheControl(res);
    const obj = JSON.parse(JSON.stringify(handleRedirectsAndReturnData(defaultProps, data, errors)));
    return obj;
}

export const getProductDataFromItemSku = async (locale: string, sku: string) => {
  const t0 = performanceStart();
  await getStore(locale);

  // ricerca per trovare product item in base al suo sku
  let productData: any;
  let { data } = await getProductByPartNumber(storeId, langId, sku);
  let skuList = data?.productViewFindProductByPartNumber?.catalogEntryView;
  if (skuList && skuList.length > 0) {
    let { data } = await getProductById(storeId, langId, skuList[0].parentCatalogEntryID);
    let productList = data?.productViewFindProductById?.catalogEntryView;
    if (productList && productList.length > 0) {
      /* @ts-ignore */
      productData = await Promise.all(productList.map(mapProductItemToCardList.bind(null, storeId, '', {}, '', langId)));
    }
    performanceLog(t0, `getProductDataFromItemSku`, { storeId, langId, sku, productData });
  }

  return productData;
}

/**
 * Load product info for short url redirect
 *
 * See: https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props
 */
export const getServerSidePropsForShortUrl: GetServerSideProps = async ({ locale, params }) => {
  const sku = getLastParamString(params?.sku);
  let nuLocale = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';

  let productData = await getProductDataFromItemSku(nuLocale, sku);
  if (!productData || productData.length === 0) {
    // se non trovo il prodotto nella lingua corrente provo a fare fallback nella lingua di default
    nuLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';
    productData = await getProductDataFromItemSku(nuLocale, sku);
  }

  if (productData && productData.length > 0) {
    return {
      redirect: {
        destination: `/${nuLocale}${encodeURI(productData[0].url)}`,
        permanent: false
      }
    };
  } else {
    return {
			// returns the default 404 page with a status code of 404
			notFound: true
		};
  }
}

/*************************** GET STATIC PATHS ***************************/

/**
 * Specific implementation of a getStaticPaths function to retrieve
 * the list of EDITORIAL PAGES (in all languages), that will be statically generated.
 *
 * @see https://nextjs.org/docs/basic-features/data-fetching#the-paths-key-required
 */
 export const getStaticPathsForPages: GetStaticPaths = async ({ locales, defaultLocale }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false' || process.env.NEXT_PUBLIC_EMPTY_GET_STATIC_PATH === 'true') {
    return {
      paths: [],
      fallback: FALLBACK,
    };
  } else {

    const t0 = performanceStart();
    const { data } = await getPageList();
    performanceLog(t0, `getStaticPathsForPages -> getPageList()`, {locales});

    const pathsData: GetStaticPathsResult['paths'] = [];
    data?.pages?.nodes &&
      data?.pages?.nodes.map((page: any) => {
        if (page && !page.isFrontPage && !isCustomPageUri(page?.uri)) {
          // Get the locale from the WPGraphQL Polylang language
          const locale = locales?.includes(page.language.slug) ? page.language.slug : defaultLocale;
          // prepare the paths data to return to Next.js getStaticPaths
          pathsData.push({
            locale: locale,
            params: { slug: page.slug },
          })
        }
      });

    return {
      paths: pathsData,
      fallback: FALLBACK,
    };
  }
}


/**
 * Specific implementation of a getStaticPaths function to retrieve
 * the list of POST DETAILS PAGES (in all languages), that will be statically generated.
 *
 * @see https://nextjs.org/docs/basic-features/data-fetching#the-paths-key-required
 */

export const getStaticPathsForPostDetail: GetStaticPaths = async ({ locales, defaultLocale }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false' || process.env.NEXT_PUBLIC_EMPTY_GET_STATIC_PATH === 'true') {
    return {
      paths: [],
      fallback: FALLBACK,
    };
  } else {

    // Get all the posts
    const t0 = performanceStart();
    const { data } = await getPostList();
    performanceLog(t0, `getStaticPathsForPostDetail -> getPostList()`, {locales});

    const pathsData: GetStaticPathsResult['paths'] = [];
    data?.posts?.nodes &&
      data?.posts?.nodes.map((post: any) => {
        const rootCategory = post?.categories?.nodes?.find((cat: any) => cat.parentId === null);
        if (rootCategory) {
          // Get the locale from the WPGraphQL Polylang language
          const locale = locales?.includes(post.language.slug) ? post.language.slug : defaultLocale;
          // prepare the paths data to return to Next.js getStaticPaths
          pathsData.push({
            locale: locale,
            params: {
              category: rootCategory.slug,
              slug: post.slug
            },
          })
        }
      });

    return {
      paths: pathsData,
      fallback: FALLBACK,
    };
  }
}

/**
 * Specific implementation of a getStaticPaths function to retrieve
 * the list of CATEGORY PAGES (in all languages), that will be statically generated.
 *
 * Note: only root categories are considered, since second level categories won't have a public page.
 *
 * @see https://nextjs.org/docs/basic-features/data-fetching#the-paths-key-required
 */
 export const getStaticPathForPostCategory: GetStaticPaths = async ({ locales, defaultLocale }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false' || process.env.NEXT_PUBLIC_EMPTY_GET_STATIC_PATH === 'true') {
    return {
      paths: [],
      fallback: FALLBACK,
    };
  } else {
    const pathsData: GetStaticPathsResult['paths'] = [];

    const t0 = performanceStart();
    const { data } = await getCategoryList();
    performanceLog(t0, `getStaticPathForPostCategory -> getCategoryList()`, {locales});

    const edges = data?.categories?.edges;
    if (edges) {
      edges.forEach((edge: any) => {
        const node = edge?.node;
        if (node && node.parentId === null) {
          const languageSlug = node?.language?.slug;
          pathsData.push({
            locale: locales?.includes(languageSlug) ? languageSlug : defaultLocale,
            params: { category: node?.slug},
          })
        }
      });
    }

    return {
      paths: pathsData,
      fallback: FALLBACK,
    };
  }
}

/**
 * Specific implementation of a getStaticPaths function to retrieve
 * the list of Catalog Brand Pages (in all languages), that will be statically generated.
 *
 * @see https://nextjs.org/docs/basic-features/data-fetching#the-paths-key-required
 */
 export const getStaticPathForCatBrand: GetStaticPaths = async ({ locales }) => {
  if (process.env.NEXT_PUBLIC_CMS_ENABLE === 'false' || process.env.NEXT_PUBLIC_EMPTY_GET_STATIC_PATH === 'true') {
    return {
      paths: [],
      fallback: FALLBACK,
    };
  } else {
    const pathsData: GetStaticPathsResult['paths'] = [];

    const t0 = performanceStart();
    performanceLog(t0, `getStaticPathForCatBrand -> ()`, {locales});

    locales?.forEach((locale: string) => {
      if (locale === 'defualt') {
        return;
      }
      pathsData.push({
        locale,
        params: { brand: 'disano' },
      });
      pathsData.push({
        locale,
        params: { brand: 'fosnova' },
      });
    });

    return {
      paths: pathsData,
      fallback: FALLBACK,
    };
  }
}

