
import { gql } from '@apollo/client'

/**
 * Get HCL commerce category details by categoryId .
 */
 export const GET_CATEGORY_BY_ID = gql`
 query GET_CATEGORY_BY_ID( $storeId: String!, $langId: String, $categoryId: String!) {
    categoryViewFindCategoryByUniqueId(storeId: $storeId, langId: $langId, categoryId: $categoryId){
        catalogGroupView {
            name
            childCatalogGroupID
            shortDescription
            uniqueID
            fullImage
            thumbnail
            longDescription
            shortDescription
            parentCatalogGroupID
        }
    }
 }
 `;