import LinksPartial, { WPLink } from "./link";
import { WPImage } from "./image";

const FasciaStandoutBannerPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_StandoutBanner`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      descrizione
      titolo
      immagine {
        ...ImageFragment
      }
      links {
        ${LinksPartial(`${fasciaBaseType}_Links`)}
      }
    }
  `
}

export default FasciaStandoutBannerPartial;

export interface WPStandoutBanner {
  titolo: string
  descrizione: string
  immagine: WPImage
  links: WPLink[]
}
