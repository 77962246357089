import { WPImage } from "./image";
import LinksPartial, { WPLink } from "./link";

const FasciaFullBannerPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_FullBanner`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      occhiello
      title
      smallTitle: smalltitle
      subtitle
      desc
      icons {
        icon
        title
        desc
        detailsTitle: detailstitle
        detailsDesc: detailsdesc
      }
      links {
        ${LinksPartial(`${fasciaBaseType}_Links`)}
      }
      textPosition: textposition
      textColorWhite: textcolorwhite
      backgroundImage: backgroundimage {
        ...ImageFragment
      }
      backgroundImageCover: backgroundimagecover
      backgroundColorMobile: backgroundcolormobile
    }
	`
}

export default FasciaFullBannerPartial;

export enum WPFullBannerBackgroundColor {
  gray = "gray",
  transparent = "transparent",
  blue = "blue",
  black = "black",
  lightbrown = "lightbrown",
  green = "green",
}

export enum WPFullBannerTextPosition {
  left = "left",
  right = "right",
  center = "center",
}

export interface WPFullBannerIcon {
  icon?: string;
  title?: string;
  desc?: string;
  detailsTitle?: string;
  detailsDesc?: string;
}

export interface WPFullBanner {
  occhiello?: string;
  title?: string;
  smallTitle?: boolean;
  subtitle?: string;
  desc?: string;
  icons?: WPFullBannerIcon[];
  links?: WPLink[];
  textPosition?: WPFullBannerTextPosition;
  textColorWhite?: boolean;
  backgroundImage: WPImage;
  backgroundImageCover?: boolean;
  backgroundColorMobile?: WPFullBannerBackgroundColor;
}

