
export const performanceStart = () => {
  return performance.now();
}

export const performanceLog = (t0: number, desc: string, params: any) => {
  if (process.env.NEXT_PUBLIC_PERFORMANCE_LOG_ENABLE === 'true') {
    if (process.env.NEXT_PUBLIC_PERFORMANCE_LOG_PARAMS_ENABLE === 'true') {
      console.log(`Performance: ${desc} took ${performance.now() - t0} milliseconds. Params: `, params);
    } else {
      console.log(`Performance: ${desc} took ${performance.now() - t0} milliseconds.`);
    }
  }
}
