const FasciaBrandCategoriesPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_BrandCategories`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      brandSlug: brandslug
    }
  `
}

export default FasciaBrandCategoriesPartial;

export interface WPBrandCategories {
  brandSlug: 'disano' | 'fosnova'
}
