import { getCategoryDataById, getProductByCategoryId, getProductByCategoryIdAndFilteredValue, getProductById, getProductByPartNumbers, getStoreDataByDirectory } from "@/data/hcl/sdk/index";
import { WPLink, WPLinks_COLORE, WPLinks_SIZE, WPLinks_VARIANTE } from "@/data/wp/operations/queries/fragments/link";
import { performanceLog, performanceStart } from "@/utils/performance";
import { buildPageUrl, PageType } from '@/utils/router';
import { getLastParamString, ServerSideParams } from "@/utils/slug";
import getT from "next-translate/getT";
import placeholderCategory from '@/public/placeholder-category.svg';
// import { integrateResponse } from "../dev/utils";

interface NewSkuAttributes {
  cablaggio : any,
  siglaCablaggio : any,
  peso : any,
  correnteNominaleDriver: any,
  led : string | any | null,
  aperturaFascio: any,
  gradiAsimmetria: any,
  wTot : any,
  potenza:  any,
  colore : any,
  garanzia : any,
  download: any
}{};

interface CategoryList {
  [key: string] : string
}{};

interface Link {
  links: WPLink[]
}

export const getStoreDataByStoreName = async (directory: string) => {
  let langId = directory.split('|')[1];
  let storeName = directory.split('|')[0];
  const t0 = performanceStart();
  let {data, errors} = await getStoreDataByDirectory(process.env.NEXT_PUBLIC_DEFAULT_STOREID,storeName);
  //Object.defineProperties(data, {storeFindByStoreNameAndUsage: {resultList: data.storeFindByStoreNameAndUsage.resultList, writable: true}});
  data = Object.assign({}, data,{selected:false});
  performanceLog(t0, `getStoreDataByStoreName -> getStoreDataByDirectory()`, {storeName});
  let storeData = data?.storeFindByStoreNameAndUsage?.resultList[0];
  if(storeData && storeData.defaultLanguageId) {
    data.langId = langId;
  }
  return {data, errors};
}

export const getProductDataByCategoryId = async (dataParams: ServerSideParams) => {

  const nuLocale = dataParams?.locale;
  const params = dataParams?.params;
  const storeId = dataParams?.storeId;
  const langId = dataParams?.langId;
  const catalogId = dataParams?.catalogId;
  const categoryId = dataParams?.categoryId;
  const orderBy = dataParams?.orderBy;
  const t = dataParams?.translate;
  const pageNumber = '1';
  const pageSize = '12';

  const data: any = {};

  data.catalogId = catalogId;
  data.locale = nuLocale;

  let category = await getCategoryDataById(storeId,langId,categoryId);
  let categoryData = category?.data?.categoryViewFindCategoryByUniqueId.catalogGroupView[0];
  data.categoryData = {
    name: categoryData?.name,
    fullImage: categoryData?.fullImage,
    shortDescription: categoryData?.shortDescription,
    longDescription: categoryData?.longDescription
  }

  data._products = [];
  data._facets = [];
  data._activeFilters = [];
  data._filterNames = [];
  data.recordSetTotal = 0;
  data.recordSetCount = 0;
  let _data: any = {
    _dataNotLoaded: true
  }
  let queryParams = {
    storeId: storeId,
    langId: langId,
    categoryId: categoryId,
    pageNumber: pageNumber,
    pageSize: pageSize,
    orderBy: orderBy
  }
  _data = await getProductByCategoryId(queryParams);
  if(_data && _data._dataNotLoaded !== true && _data?.data?.productViewFindProductsByCategory?.recordSetTotal > 0) {
      data.recordSetTotal = _data?.data?.productViewFindProductsByCategory?.recordSetTotal;
      data.recordSetCount = _data?.data?.productViewFindProductsByCategory?.recordSetCount;
      data._products = await Promise.all(_data?.data?.productViewFindProductsByCategory?.catalogEntryView?.map(mapProductItemToCardList.bind(null,storeId, categoryId,params, '', '')));

      let facetList = _data?.data?.productViewFindProductsByCategory?.facetView;
      facetList.forEach(async (item: any) => {
        let facet : any = {
            accordionTitle: "",
            accordionCheckbox: []
        }
        let sliderList : any[] = [];
        let sliderValues : any[] = [];
        let slider : any = {
          accordionTitle: "",
          accordionSlider: {
            valueMin: 0,
            valueMax: 100,
            label: 'W',
            marks: ['']
          }
        }
        if(item?.value === 'parentCatgroup_id_search'){
          facet.accordionTitle = t('category-filter-title');
        } else if(!item?.extendedData?.srchattridentifier?.substring(5).includes('OfferPrice')){
          facet.accordionTitle = item?.name;
        }
        let entries = item?.entry;
        entries.forEach(async (entry : any) => {
          let newEntry = {};
          if(item?.value === 'parentCatgroup_id_search'){
            let newEntryValue = catalogId + '_' + entry.value;
            newEntry = {
              label: entry.label.replace('&nbsp;',''),
              value: item?.value + ':' + newEntryValue
            };
            facet.accordionCheckbox.push(newEntry);
          } else if(
            item?.extendedData?.srchattridentifier?.substring(5) === 'att_Efficienza' ||
            item?.extendedData?.srchattridentifier?.substring(5) === 'att_EfficienzaLuminosa' ||
            item?.extendedData?.srchattridentifier?.substring(5) === 'att_FlussoLuminosoDefinitivo' ||
            item?.extendedData?.srchattridentifier?.substring(5) === 'att_FlussoLuminosoUscenteCal' ||
            item?.extendedData?.srchattridentifier?.substring(5) === 'att_PotenzaTotApparecchio' ||
            item?.extendedData?.srchattridentifier?.substring(5) === 'att_Diametro'
          ) {
            sliderList.push({
              value: Number(entry.label.split(' ')[0]),
              valueForQuery: decodeURIComponent(entry.value)
            });
            sliderValues.push(parseInt(entry.label.split(' ')[0]));
          } else if(!item?.extendedData?.srchattridentifier?.substring(5).includes('OfferPrice')){
            newEntry = {
              label: entry.label.replace('&nbsp;',''),
              value: decodeURIComponent(entry.value)
            };
            facet.accordionCheckbox.push(newEntry);
          }
        });
        if(!item?.extendedData?.srchattridentifier?.substring(5).includes('OfferPrice')){
          data?._filterNames.push(item?.value);
          //slider
          if(sliderList.length > 0) {
            slider.accordionTitle = item?.name;
            slider.accordionSlider.valueMin = Math.min(...sliderValues);
            slider.accordionSlider.valueMax = Math.max(...sliderValues);
            const splittedValueForQuery = sliderList[0].valueForQuery.replace(/"/g, '').split('+');
            if (splittedValueForQuery.length === 2) {
              slider.accordionSlider.label = splittedValueForQuery[1];
            }
            slider.accordionSlider.label = item?.extendedData?.srchattridentifier?.substring(5) === 'att_Diametro' ? 'mm' : slider.accordionSlider.label;            
            slider.accordionSlider.marksValues = sliderValues.sort((a,b) => a < b ? -1 : 1);
            slider.accordionSlider.marks = sliderList.sort((a,b) => a.value < b.value ? -1 : 1);
            data?._facets.push(slider);
          } else {
            facet.accordionCheckbox.sort((a:any, b:any) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

            //checkbox
            if(!Number.isNaN(facet.accordionCheckbox[0].label.split(' ')[0])){
              // ordino numericamente
              facet.accordionCheckbox.sort((a: any,b: any) => Number(a.label.split(' ')[0]) < Number(b.label.split(' ')[0]) ? -1 : 1)
            }
            
            data?._facets.push(facet);
          }
        }
      });
      data.langId = langId;
  } else {
    _data = {
      _dataNotLoaded: true
    }
  }
  return {data};
}

export const getFilteredValuesByCategoryId = async (queryParams: any) => {

  // console.debug("getFilteredValuesByCategoryId: ", queryParams);
  const t = await getT(queryParams.locale, 'common');
  let sliderFilter: any[] = [];
  let activeFilters : string = queryParams?.facetList.toString();
  let activeFilterList = queryParams?.facetList;
  let activeFiltersForQuery : string = "";
  if(activeFilterList.length > 0){
    activeFiltersForQuery = await getActiveFiltersForQuery(queryParams.facetList, queryParams.filterNames);
  }
  let categoryList: CategoryList = {};
  let { data, errors } = await getProductByCategoryIdAndFilteredValue(queryParams.storeId, queryParams.langId, queryParams.categoryId, activeFiltersForQuery, queryParams.orderBy, queryParams?.pageNumber,queryParams?.pageSize);
  data = Object.assign({}, data, {selected:false})
  data._products = [];
  data._facets = [];
  data.recordSetTotal = 0;
  data.recordSetCount = 0;
  data.recordSetTotal = data?.productViewFindProductsByCategory?.recordSetTotal;
  data.recordSetCount = data?.productViewFindProductsByCategory?.recordSetCount;
  data._products = await Promise.all(data?.productViewFindProductsByCategory?.catalogEntryView?.map(mapProductItemToCardList.bind(null,queryParams.storeId, queryParams.categoryId,queryParams.params, '', '')));

  let facetList = data?.productViewFindProductsByCategory?.facetView;
  facetList?.forEach(async (item: any) => {
    let facet : any = {
        accordionTitle: "",
        accordionCheckbox: []
    }
    let sliderList : any[] = [];
    let sliderValues : any[] = [];
    let slider : any = {
      accordionTitle: "",
      accordionSlider: {
        valueMin: 0,
        valueMax: 100,
        label: 'W',
        marks: [''],
        isReset: queryParams.sliderFilterValueDeleted ? true : false
      }
    }
    if(item?.value === 'parentCatgroup_id_search'){
      facet.accordionTitle = t('category-filter-title');
    } else if(!item?.extendedData?.srchattridentifier?.substring(5).includes('OfferPrice')){
      facet.accordionTitle = item?.name;
    }
    let entries = item?.entry;
    entries.forEach(async (entry : any) => {
      let newEntry = {};
      if(item?.value === 'parentCatgroup_id_search'){
        let newEntryValue = queryParams.catalogId + '_' + entry.value;
        newEntry = {
          label: entry.label.replace('&nbsp;',''),
          value: item?.value + ':' + newEntryValue
        };
        let keyVal = item?.value + ':' + newEntryValue;
        categoryList[keyVal] = entry.label.replace('&nbsp;','');
        facet.accordionCheckbox.push(newEntry);
      } else if (
        item?.extendedData?.srchattridentifier?.substring(5) === 'att_Efficienza' ||
        item?.extendedData?.srchattridentifier?.substring(5) === 'att_EfficienzaLuminosa' ||
        item?.extendedData?.srchattridentifier?.substring(5) === 'att_FlussoLuminosoDefinitivo' ||
        item?.extendedData?.srchattridentifier?.substring(5) === 'att_FlussoLuminosoUscenteCal' ||
        item?.extendedData?.srchattridentifier?.substring(5) === 'att_PotenzaTotApparecchio' ||
        item?.extendedData?.srchattridentifier?.substring(5) === 'att_Diametro'
      ) {
        sliderList.push({
          value: Number(entry.label.split(' ')[0]),
          valueForQuery: decodeURIComponent(entry.value)
        });

        sliderValues.push(parseInt(entry.label.split(' ')[0]));

        if(!sliderFilter.includes(item?.value)){
          sliderFilter.push(item?.value);
        }
      } else if(!item?.extendedData?.srchattridentifier?.substring(5).includes('OfferPrice')){
        newEntry = {
          label: entry.label.replace('&nbsp;',''),
          value: decodeURIComponent(entry.value)
        };
        facet.accordionCheckbox.push(newEntry);
      }
    });
    if(!item?.extendedData?.srchattridentifier?.substring(5).includes('OfferPrice')){
      //slider
      if(sliderList.length > 0) {
        slider.accordionTitle = item?.name;
        slider.accordionSlider.valueMin = Math.min(...sliderValues);
        slider.accordionSlider.valueMax = Math.max(...sliderValues);        
        const splittedValueForQuery = sliderList[0].valueForQuery.replace(/"/g, '').split('+');
        if (splittedValueForQuery.length === 2) {
          slider.accordionSlider.label = splittedValueForQuery[1];
        }
        slider.accordionSlider.label = item?.extendedData?.srchattridentifier?.substring(5) === 'att_Diametro' ? 'mm' : slider.accordionSlider.label;
        slider.accordionSlider.marksValues = sliderValues.sort((a,b) => a < b ? -1 : 1);
        slider.accordionSlider.marks = sliderList.sort((a,b) => a.value < b.value ? -1 : 1);
        data?._facets.push(slider);
      } else {
        
        facet.accordionCheckbox.sort((a:any, b:any) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        //checkbox
        if(!Number.isNaN(facet.accordionCheckbox[0].label.split(' ')[0])){
          // ordino numericamente
          facet.accordionCheckbox.sort((a: any,b: any) => Number(a.label.split(' ')[0]) < Number(b.label.split(' ')[0]) ? -1 : 1)
        }

        data?._facets.push(facet);
      }
    }
  });

  if(activeFilters && activeFilters !== ''){
    let sliderFilterListValue: any[] = [];
    let sliderFilterListLabel: any[] = [];
    data._activeFilters = [];

    let unitOfMeasure = 'W';
    sliderFilter.forEach(filter => {
      let arr : any[] = [];

      data._activeFilters = [];
      activeFilters.split(',').forEach((filterValue : string) => {
        if(filterValue.includes(filter)){
          if(filterValue.includes('mm'))
            unitOfMeasure = 'mm';
          arr.push(filterValue.split(':')[1]?.replaceAll("\"", ""));
          sliderFilterListLabel.push(filterValue.split(':')[1]?.replaceAll("\"", ""));
        }
      });
      sliderFilterListValue.push(arr);

      activeFilters.split(',').forEach((filterValue : string) => {
        if(!sliderFilterListLabel.includes(filterValue.split(':')[1]?.replaceAll("\"", ""))){
          data._activeFilters.push({
            name: filterValue,
            value: getActiveFilterValue(filterValue, categoryList)
          });
        }
      });
    });


    if(sliderFilterListValue.length > 0) {
      sliderFilterListValue.forEach(sf => {
        if(sf.length > 0){
          let sfNumber: number[] = [];
          sf.forEach((value: string) => {
            sfNumber.push(Number(value.split('+')[0]));
            unitOfMeasure = value.split('+')[1];
            //console.debug('sfNumber + unitOfMeasure: %s %s', JSON.stringify(sfNumber), unitOfMeasure)
          });
          data._activeFilters.push({
            name: sf,
            value: Math.min(...sfNumber) + unitOfMeasure + '<' + Math.max(...sfNumber) + unitOfMeasure
          });
        }
      })
      //console.debug('sliderFilterListValue: %s', JSON.stringify(sliderFilterListValue))
    }
    //console.debug('activeFilters: %s', JSON.stringify(activeFilters))
  }
    return { data, errors };
}

const getActiveFilterValue = (filterValue: string, categoryList: CategoryList): any => {
  if(filterValue.includes('parentCatgroup_id_search')) {
    //console.debug('categoryList: %s', JSON.stringify(categoryList))
    return categoryList[filterValue];
  }
  return filterValue.split(':')[1]?.replaceAll("\"", "").replaceAll('+',' ');
}


export const getProductDataById = async (dataParams: ServerSideParams) => {

  const nuLocale = dataParams?.locale;
  const params = dataParams?.params;
  const storeId = dataParams?.storeId;
  const langId = dataParams?.langId;
  const productId = dataParams?.productId;
  const categoryId = dataParams?.categoryId;
  const t = dataParams.translate;
  //const hostname = dataParams.hostname;
  const rootCategorySlug = 'cat';
  let brandSlug = '';
  let categoryIdSlug = '';
  let productIdSlug = '';
  if (params?.brand) {
    brandSlug = rootCategorySlug + "/" + getLastParamString(params?.brand);
  }
  if (params?.category && params?.brand) {
    categoryIdSlug = brandSlug + "/" + getLastParamString(params?.category);
  }
  if (params?.product && params?.category) {
    productIdSlug = categoryIdSlug + "/" + getLastParamString(params?.product);
  }

  params['slug'] = t('garanzie-slug');

  const data: any = {};

  data.product = {};
  data.attachments = [];
  data.dimensioniImg = '';
  data.iconList = [];
  data.descriptionList = [];
  data.singleTable = [];
  data.versionsSkuRows = [];
  data.merchandisingAssociationsRows = [];
  data.relatedProducts = [];
  data.breadcrumbs = [];
  let familyPartNumbers: any = [];

  let category = await getCategoryDataById(storeId,langId,categoryId);
  let categoryData = category?.data?.categoryViewFindCategoryByUniqueId.catalogGroupView[0];

  let _data: any = {
    _dataNotLoaded: true
  }
  _data = await getProductById(storeId,langId,productId);
  if(_data && _data._dataNotLoaded !== true && _data?.data?.productViewFindProductById?.catalogEntryView.length > 0) {
    let product = _data?.data?.productViewFindProductById?.catalogEntryView[0];
    data.product = product;

    let brandBreadcrumb = getBreadcrumbLinks(brandSlug,params?.brand,true, false);

    let categoryBreadcrumb = getBreadcrumbLinks(categoryIdSlug,categoryData?.name, false, true);

    let productBreadcrumb = getBreadcrumbLinks(productIdSlug,product?.shortDescription.replaceAll('&nbsp;',' '), false, false);

    data.breadcrumbs = [brandBreadcrumb, categoryBreadcrumb, productBreadcrumb];

    let icons = product.attachments?.filter((item: { usage: string; }) => item.usage.includes('Pittogrammi'));
    icons?.forEach(async (icon: any) => {
        let iconObj = {
          icon:  normalizeAzureAssetUrl(icon.attachmentAssetPath, ''),
          iconTooltip: icon.longdesc
        }
        data.iconList.push(iconObj);
    });

    let attributes = product?.attributes;
    data.tipoMont = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_TipoMont');
    data.colore = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_ColoreCorpo');
    data.ottica = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_OtticaDesc');
    data.montaggio = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Montaggio');
    data.normative = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_UgrDesc');
    data.appUso = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_LuogoInstallazione');

    data.lunghezza = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Lunghezza');
    data.larghezza = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Larghezza');
    data.altezza = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Altezza');
    data.dimIncasso = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Diametro');

    data.dimensionsAttr = ['att_Lunghezza','att_Larghezza','att_Altezza','att_Diametro'];
    data.dimensions = {
      att_Lunghezza: attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Lunghezza'),
      att_Larghezza: attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Larghezza'),
      att_Altezza: attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Altezza'),
      att_Diametro: attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Diametro')
    }

    //ATTACHMENTS
     let attachments = data?.product?.attachments;
     attachments?.forEach((item: any) => {
       if(item.identifier && item.identifier !== '' && !item.usage.includes('Pittogrammi')) {
        if(item.usage === 'DisegnoTecnicoJPG'){
          data.dimensioniImg = item.attachmentAssetPath;
        } else if(item.usage === 'ZipTutti'){
          data.downloadZip.push(item);
        } else {
          data.attachments.push(item);
        }
       }
     });

    data.productDetailInfo = {
      desc: []
    };
    if(data?.colore[0]?.name !== undefined && data?.colore[0]?.values[0].value !== undefined){
      data?.productDetailInfo?.desc.push({
        name: data?.colore[0]?.name,
        value: data?.colore[0]?.values[0].value
      });
    }
    if(data?.ottica[0]?.name !== undefined && data?.ottica[0]?.values[0].value !== undefined){
      data?.productDetailInfo?.desc.push({
        name: data?.ottica[0]?.name,
        value: data?.ottica[0]?.values[0].value
      });
    }
    if(data?.montaggio[0]?.name !== undefined && data?.montaggio[0]?.values[0].value !== undefined){
      data?.productDetailInfo?.desc.push({
        name: data?.montaggio[0]?.name,
        value: data?.montaggio[0]?.values[0].value
      });
    }

    //VERSIONI
    const showGaranzia = false;
    let sKUs = product?.sKUs;
    let skuTableTitleFull = {
      codice: t('codice-th-title'), 
      cablaggio: t('cablaggio-th-title'), 
      siglaCablaggio: t('siglaCablaggio-th-title'), 
      peso: t('peso-th-title'), 
      correnteNominaleDriver: t('correnteNominaleDriver-th-title'), 
      lumen: t('lumen-output-th-title'), 
      gradiAsimmetria: t('gradiAsimmetria-th-title'), 
      aperturaFascio: t('aperturaFascio-th-title'),  
      wTot: t('watt-tot-th-title'), 
      potenza: t('potenza-th-title'), 
      colore: t('colore-th-title'), 
      garanzia: t('garanzia-th-title'), 
      download: t('download-th-title')
    };
    let skuTableTitleDelta = [];
    let tableAttrs: NewSkuAttributes = {
      cablaggio: '',
      siglaCablaggio: '',
      colore: '',
      garanzia: '',
      correnteNominaleDriver: '',
      gradiAsimmetria: '',
      potenza: '',
      led: '',
      aperturaFascio: '',
      peso: '',
      wTot: '',
      download: ''
    };
    let tableAttrsColList: NewSkuAttributes = {
      cablaggio: [],
      siglaCablaggio: [],
      colore: [],
      garanzia: [],
      correnteNominaleDriver: [],
      gradiAsimmetria: [],
      potenza: [],
      led: [],
      aperturaFascio: [],
      peso: [],
      wTot: [],
      download: []
    };
    sKUs?.forEach((sku: any) => {
      let attributes = sku?.attributes;
      // let attributes = integrateResponse(sku).attributes;
      /* let att_FlussoUscentePreliminare = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_FlussoUscentePreliminare');
      let att_FlussoLuminosoDefinitivo = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_FlussoLuminosoDefinitivo'); */
      let att_FlussoLuminosoUscenteCal = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_FlussoLuminosoUscenteCal');
      let att_Cct = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Cct');
      let att_CriMkt = product?.attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_CriMkt');
      let att_CriMktItem = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_CriMkt');
      if (att_CriMktItem.length > 0){ att_CriMkt = att_CriMktItem; }
      // console.log(product);

      /**
       * Check if is undefined or not
       * 
       * @param newAttrs 
       * @returns 
       */
      const hasGetWTot = function(newAttrs: any){
        if  (newAttrs?.wTot[0]?.length === 0) 
          { return false; }
        if (typeof newAttrs?.wTot[0]?.values[0] === 'undefined') 
          { return false; }
        if (typeof newAttrs?.wTot[0]?.values[0]?.value === 'undefined' || newAttrs?.wTot[0]?.values[0]?.value === '0 W') 
          { return false; }

        return true;
      }

      const newAttrs : NewSkuAttributes = {
        cablaggio : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Cablaggio'),
        siglaCablaggio : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_SiglaCablaggio'),
        peso : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Peso'),
        correnteNominaleDriver : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_CorrenteNominaleDriver'),
        led : getLumenOutputKCri(att_FlussoLuminosoUscenteCal[0]?.values[0].value,att_Cct[0]?.values[0].value,att_CriMkt[0]?.values[0].value),
        aperturaFascio : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_AperturaFascio'),
        gradiAsimmetria : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_GradiAsimmetria'),
        wTot : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_PotenzaTotApparecchio'),
        colore : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_ColoreCorpo'),
        potenza : attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_Potenza'),
        garanzia: buildPageUrl(PageType.PAGE, params, nuLocale),
        download: normalizeAzureAssetUrl(data.downloadZip, '/')
      };
      if (newAttrs.colore.length === 0){ newAttrs.colore = data?.colore; }

      if (newAttrs.aperturaFascio.length === 0){
        newAttrs.aperturaFascio = product.attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_AperturaFascio');
      }

      tableAttrsColList['cablaggio'].push(newAttrs?.cablaggio[0]?.values[0].value || null)
      tableAttrsColList['siglaCablaggio'].push(newAttrs?.siglaCablaggio[0]?.values[0].value || null)
      tableAttrsColList['peso'].push(newAttrs?.peso[0]?.values[0].value || null)
      tableAttrsColList['correnteNominaleDriver'].push(newAttrs?.correnteNominaleDriver[0]?.values[0].value || null)
      tableAttrsColList['led'].push(newAttrs?.led === ' ' ? null : newAttrs?.led)
      tableAttrsColList['aperturaFascio'].push(newAttrs?.aperturaFascio[0]?.values[0].value || null)
      tableAttrsColList['gradiAsimmetria'].push(newAttrs?.gradiAsimmetria[0]?.values[0].value || null)
      tableAttrsColList['wTot'].push( hasGetWTot(newAttrs) === false ? null : newAttrs?.wTot[0]?.values[0].value)
      tableAttrsColList['potenza'].push(newAttrs?.potenza[0]?.values[0].value || null)
      tableAttrsColList['colore'].push(newAttrs?.colore[0]?.values[0].value || null)

      tableAttrs = newAttrs;

      let tableRow = {
        sku: sku?.partNumber,
        openModalLabel: sku?.partNumber,
        storeId: storeId,
        langId: langId,
        breadcrumbs: data.breadcrumbs,
        attachments: data.attachments,
        parentProductName: data.product.shortDescription,
        fullImage: data.product.fullImage,
        appUso: data?.appUso[0]?.values[0].value,
        thumbnail: data.product.thumbnail,
        cablaggio: newAttrs?.cablaggio[0]?.values[0].value || ' ',
        siglaCablaggio: newAttrs?.siglaCablaggio[0]?.values[0].value || ' ',
        kg: newAttrs?.peso[0]?.values[0].value || ' ',
        correnteNominaleDriver: newAttrs?.correnteNominaleDriver[0]?.values[0].value || ' ',
        gradiAsimmetria: newAttrs?.gradiAsimmetria[0]?.values[0].value || ' ',
        potenza:  newAttrs?.potenza[0]?.values[0].value || ' ',
        lumen: newAttrs?.led,
        aperturaFascio: newAttrs?.aperturaFascio[0]?.values[0].value || ' ',
        wtot: hasGetWTot(newAttrs) === false ? ' ' : newAttrs?.wTot[0]?.values[0].value,
        colore: newAttrs?.colore[0]?.values[0].value || ' ',
        garanzia: newAttrs.garanzia || null,
        download: process.env.NEXT_PUBLIC_ENABLE_ZIP === 'true' ? (newAttrs.download || null) : null
      }
      data?.versionsSkuRows.push(tableRow);
    });

    const allEqual = (arr: any[],param : string | null) => arr.every(val => val === param);

    let activeColumns : NewSkuAttributes = {
      cablaggio: false,
      siglaCablaggio: false,
      colore: false,
      garanzia: false,
      correnteNominaleDriver: false,
      gradiAsimmetria: false,
      potenza: false,
      led: false,
      aperturaFascio: false,
      peso: false,
      wTot: false,
      download: false
    };
    skuTableTitleDelta.push(skuTableTitleFull.codice);
    if(!allEqual(tableAttrsColList['cablaggio'],null)){
      activeColumns['cablaggio'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.cablaggio);
    }
    if(!allEqual(tableAttrsColList['siglaCablaggio'],null)){
      activeColumns['siglaCablaggio'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.siglaCablaggio);
    }
    if(!allEqual(tableAttrsColList['peso'],null)){
      activeColumns['peso'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.peso);
    }
    if(!allEqual(tableAttrsColList['correnteNominaleDriver'],null)){
      activeColumns['correnteNominaleDriver'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.correnteNominaleDriver);
    }
    if(!allEqual(tableAttrsColList['led'],null)){
      activeColumns['led'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.lumen);
    }
    if(!allEqual(tableAttrsColList['aperturaFascio'],null)){
      activeColumns['aperturaFascio'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.aperturaFascio);
    }
    if(!allEqual(tableAttrsColList['gradiAsimmetria'],null)){
      activeColumns['gradiAsimmetria'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.gradiAsimmetria);
    }
    if(!allEqual(tableAttrsColList['wTot'],null)){
      activeColumns['wTot'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.wTot);
    }
    if(!allEqual(tableAttrsColList['potenza'],null)){
      activeColumns['potenza'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.potenza);
    }
    if(!allEqual(tableAttrsColList['colore'],null)){
      activeColumns['colore'] = true;
      skuTableTitleDelta.push(skuTableTitleFull.colore);
    }
    if (showGaranzia) {
      skuTableTitleDelta.push(skuTableTitleFull.garanzia);
    }
    if(process.env.NEXT_PUBLIC_ENABLE_ZIP === 'true' && tableAttrs?.download){
      skuTableTitleDelta.push(skuTableTitleFull.download);
    }

    data.versionsTable = {
      tabTitle: t('versions-tab-title'),
      tableInfo: {
        th: skuTableTitleDelta,
        tr: data?.versionsSkuRows,
        activeColumns: activeColumns
      }
    };


    //ACCESSORI/PRODOTTI
    let merchandisingAssociations = product?.merchandisingAssociations;

    if(merchandisingAssociations && merchandisingAssociations !== null){
      data.merchandisingAssociationsRows = await Promise.all(merchandisingAssociations?.map(mapMerchAssoc.bind(null, storeId, langId, nuLocale, params)));
      if(data.merchandisingAssociationsRows){
        let associationType = data.merchandisingAssociationsRows[0].associationType;
        data.accessoryTable = {
          tabTitle: associationType === 'ACCESSORY' ? t('accessory-tab-title') : t('product-tab-title'),
          tableInfo: {
            th: ["", t('name-th-title'), t('desc-th-title')],
            tr: data?.merchandisingAssociationsRows
          }
        };
      }
    }

    //DIMENSIONI
    data.dimensionsRow = [];

    data?.dimensionsAttr?.forEach((attribute: any) => {
      if(data.dimensions[attribute].length > 0) {
        data.dimensionsRow.push({
          name: data.dimensions[attribute][0].name,
          value: data.dimensions[attribute][0]?.values[0]?.value
        });
      }
    });

    data.dimensionsTable = {
      tabTitle: t('dimension-tab-title'),
      dimensioniImg: { id: "product-detail-dimension", mimeType: "image/webp", mediaItemUrl: data?.dimensioniImg, mediaDetails: { width: 502, height: 502 } },
      dimensioniTable: data.dimensionsRow
    };
    familyPartNumbers = attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_ProdottiCorrelati');
  } else {
    _data = {
      _dataNotLoaded: true
    }
  }

  if(data.versionsSkuRows?.length > 0){
    data.singleTable.push(data.versionsTable);
  }
  if(data.merchandisingAssociationsRows?.length > 0){
    data.singleTable.push(data.accessoryTable);
  }
  data.singleTable?.push(data.dimensionsTable);

  let _dataRelatedProductList: any = {
    _dataNotLoaded : true
  };
  if(familyPartNumbers && familyPartNumbers.length > 0) {
    let partNumbers : string[] = [];
    partNumbers = Object.assign([], familyPartNumbers[0]?.values[0]?.value?.split('|'))
    if(partNumbers.length > 4){
      partNumbers = partNumbers.slice(0,4);
    }
    _dataRelatedProductList = await getProductByPartNumbers(storeId,langId,partNumbers);
    if(_dataRelatedProductList && _dataRelatedProductList._dataNotLoaded !== true){
      data.relatedProducts = await Promise.all(_dataRelatedProductList?.data?.productViewFindProductByPartNumbers?.catalogEntryView?.map(mapProductItemToCardList.bind(null,storeId, '',params,'',langId)));
    }
  }

  return {data};
}

/**
 * Dato un item contenente i dati di prodotto (catalogEntryView) nel formato restituito da HCL,
 * effettua il mapping restituendo un oggetto da passare al componente WhiteCard o, sottoforma di array, CardList.
 *
 * Usage example:
 *  const cardListProducts = data?.productViewFindProductsByCategory?.catalogEntryView?.map(mapProductItemToCardList);
 */
export const mapProductItemToCardList = async (storeId: string, categoryId: string, params: any, locale: string, langId: string, item: any) => {
  // Detail url
  if(params === undefined){
    params = {};
  }
  params['product'] = cleanProductUrlParam(item?.uniqueID + '-' + item?.shortDescription);
  let detailUrl = '';
  if(categoryId && categoryId !== ''){
    detailUrl = buildPageUrl(PageType.PDP, params);
  }else{
    detailUrl = await getProductUrl(item.parentCatalogGroupID[1].split('_')[1], item?.uniqueID, item?.shortDescription, storeId, langId, locale, params);
  }

  // Image
  // TODO: dinamicizzare le info dell'immagine anteprima del prodotto (es: label and img)
  const image = { id: 'card-image', mimeType: 'image/webp', mediaItemUrl: item.thumbnail ? item.thumbnail : null, mediaDetails: { width: 255, height: 255 } };

  return {
    url: detailUrl,
    //label: "Novita",
    img: image,
    title: item?.shortDescription,
    storeId: storeId,
    categoryId: categoryId
  };
}

export const cleanProductUrlParam = (urlComponent: string) => {
  let cleaned = urlComponent;
  cleaned = cleaned.replaceAll('&nbsp;',' ');
  // cleaned = sanitize(cleaned);
  // remove url reserved chars: \n \r ␣ ! " # $ % & ' ( ) * + , : ; = ? @ [ ] . < > \ ^ ` { | } ~ £ 円
  cleaned = urlComponent.replaceAll(/[\n\r␣!"#\$%&'\(\)\*\+,:;=\?@\[\].<>\\\^`{\|}~£円]/g, '');
  // remove other weird chars: ø ° ≤ ≥
  cleaned = cleaned.replaceAll(/[ø°≤≥]/g, '');
  // sostituisce con il trattino qualsiasi carattere diverso da numeri e lettere (considerando anche alfabeti diversi dal latino).
  cleaned = cleaned.replaceAll(/[^\p{L}\p{N}]/gu, '-').toLowerCase();
  return cleaned;
}

const mapMerchAssoc = async (storeId: string, langId: string, nuLocale: string, params: any, merchandisingAssociation: any) => {
  return {
    immagine: { id: "product-detail-accessori-1", mimeType: "image/webp", mediaItemUrl: merchandisingAssociation?.thumbnail ? merchandisingAssociation?.thumbnail : placeholderCategory, mediaDetails: { width: 87, height: 87 } },
    sku: merchandisingAssociation?.partNumber,
    merchUrl: await getProductUrl(merchandisingAssociation.parentCatalogGroupID[1].split('_')[1], merchandisingAssociation?.uniqueID, merchandisingAssociation?.shortDescription, storeId, langId, nuLocale, params),
    nome: merchandisingAssociation?.shortDescription,
    descrizione: merchandisingAssociation?.shortDescription,
    associationType: merchandisingAssociation?.associationType
  };
}

const getActiveFiltersForQuery = async (activeFilterList: string[], facetIds: string[]) => {
let activeFiltersForQuery: string = "";
let activeFiltersForQueryList: string[] = [];
if(activeFilterList.length > 0) {
    facetIds.forEach((facetId : string) => {
      let tmpSameValList = [];
      tmpSameValList = activeFilterList.filter((activeFilter : string) => activeFilter.includes(facetId));
      if(tmpSameValList.length > 1) {
        activeFiltersForQueryList.push('(' + tmpSameValList.join(' OR ') + ')');
      } else if(tmpSameValList.length === 1) {
        activeFiltersForQueryList.push(tmpSameValList.toString());
      }
    });
    if(activeFiltersForQueryList.length > 1) {
      activeFiltersForQuery = activeFiltersForQueryList.join(' AND ');
    } else if(activeFiltersForQueryList.length === 1){
      activeFiltersForQuery = activeFiltersForQueryList.toString();
    }
}
return activeFiltersForQuery;
}

const getBreadcrumbLinks = (slug: string, name: string, isRoot: boolean, disableClientSideNavigation: boolean = false) => {
let linkObj : Link = {
  links : []
};
let breadcrumbLink : WPLink = {
  __typename: "DefaultTemplate_Fasce_Fasce_Showroom-List_Links_LinkInterno",
    linkInterno: {
      id: '',
      slug: slug,
      uri: "/" + slug + "/",
      title: slug
    },
    label: isRoot === true ? name.toUpperCase() : name,
    target: null,
    variante: WPLinks_VARIANTE.plainLink,
    colore: WPLinks_COLORE.primary,
    dimensione: WPLinks_SIZE.large,
    disableClientSideNavigation: disableClientSideNavigation,
  }
  linkObj.links.push(breadcrumbLink);
return linkObj;
}


export const getTechnicalDataAttributeKey = () => {
  return [
  // INFORMAZIONI GENERALI
  // DIMENSIONI_E_PESO
  // INSTALLAZIONE
  // CARATTERISTICHE_ELETTRICHE_E_CONTROLLI
  // DATI_FOTOMETRICI
  // CARATTERISTICHE_MECCANICHE
  // MATERIALI_E_COLORI
  // EMERGENZA
  // NORME_E_CONFORMITA
  // DOTAZIONI
  // GARANZIA

  'att_Produttore', 'att_CodiceBreveProdotto', 'att_CodiceOrdine', 'att_CodiceUnivocoEuropeo',
  'att_NomeArtic', 'att_NomeDesigner', 'att_CorpoDesc', 'att_AttaccoPalo', 'att_Base', 'att_ColoreCorpo',
  'att_Diffusore', 'att_OtticaDesc', 'att_TipoDiDistribuzioneFotometrica',
  'att_Calpestabile', 'att_Calpestabile', 'att_Ugr', 'att_UgrDesc', 'att_Verniciatura', 'att_Verniciatura2', 'att_VerniciaturaSpeciale',
  'att_Dissipatore', 'att_SuperficieEspostaVentoFrontale', 'att_SuperficieEspostaVentoLaterale', 'att_SiglaCablaggio', 'att_Cablaggio',
  'att_FrequenzaNominale', 
  'att_TipoDiVoltaggio', 'att_TensioneMin', 'att_TensioneMax', 'att_Tensione', 'att_FrequenzaMin', 'att_FrequenzaMax', 
  'att_Controllo','att_TecnologiaSensoreCal', 'att_InterfacciaCal', 'att_FunzioniIntegrateCal', 'att_RegIndipendLuceDirettaIndiretta',
  'att_LowFlicker', 'att_AperturaFascio', 'att_GradiAsimmetria', 'att_FattoreDiPotenza', 'att_SurgeComune',  
  'att_SurgeDifferenziale', 'att_CapElettricaMaxFase', 'att_CaricoMax', 'att_EquipagDotaz', 'att_CollegamentoPresaSpinaIncluso',
  'att_ARichiesta', 'att_LunghezzaCavo', 'att_NomeLampada', 'att_CorrenteNominaleDriver', 'att_FlussoLuminosoUscenteCal', 'att_FlussoLuminosoUscenteLineare',
  'att_PotenzaLampMetro', 'att_NumLedMetro', 'att_PotenzaMax', 'att_PotenzaMin', 'att_PotenzaTotApparecchio', 'att_PPE', 'att_PPF',
  'att_TemperaturaAmbiente', 'att_Cct', 'att_HclTwCctMax', 'att_HclTwCctMin', 'att_CctMkt', 'att_CriMkt', 'att_ConsistenzaCromaticaCal',
  'att_Efficienza', 'att_EfficienzaLuminosa', 'att_TempAmbieMax', 'att_TempAmbieMin', 'att_LumenMaintenance', 'att_FailureRate', 'att_LedRatedLife',
  'att_LedRatedLifeUV', 'att_RischioFotobiologico', 'att_Normativa',  'att_TestDiLaboratorio', 'att_Marcature', 'att_RegisteredDesign', 'att_TestResistenzaFiloACaldo',
  'att_ClasseIsolamentoElettrico', 'att_Ip', 'att_IpParteOttica', 'att_IpVanoAccessori', 'att_Ik', 'att_Altezza', 'att_Larghezza', 'att_Lunghezza', 'att_Diametro',
  'att_DiametroFinale', 'att_Peso', 'att_DiametroMax', 'att_DiametroMin', 'att_PaloAltezzaFuoriTerra', 'att_PaloAltezzaInterrata', 'att_DimensioniCodolo',
  'att_DimensInstallaziLarghezza', 'att_DimensInstallaziLunghezza', 'att_DimensInstalDiametroIncasso', 'att_DimInstallaziDiametroIncassoMax', 'att_DimInstallaziDiametroIncassoMin',
  'att_Warnings', 'att_Garanzia', 'att_Sensori',
  'att_CampoRilevamento', 'att_EmergenzaSA-SE', 'att_DurataBatteria', 'att_ZonaAtex', 'att_ClasseTemperaturaAtex', 'att_EtichettaEnergeticaLed'];
};

export const getTechnicalDataAttributeValue = (attributeKeys: string[], attributes: any) => {
  let technicalDataMap: any = {};
  attributeKeys.forEach(elem => {
    if(attributes?.filter((item: { identifier: string; }) => item.identifier === elem) !== undefined){      
      technicalDataMap[elem] = attributes?.filter((item: { identifier: string; }) => item.identifier === elem);
      if(elem === 'att_FattoreDiPotenza'){
        technicalDataMap['att_SegnoFattorePotenza'] = (attributes?.filter((item: { identifier: string; }) => item.identifier === 'att_SegnoFattorePotenza'));
      }
    }
  });

  // Se compilate att_TensioneMin e att_TensioneMax, non pubblicare att_Tensione. Se non presenti, pubblicare att_Tensione.
  // Ovviamente deve essere anche attiva la cancellazione del dato dal sito, in caso cambino gli attributi compilati in PIM
  if (
      technicalDataMap['att_TensioneMin'] && technicalDataMap['att_TensioneMin'].length > 0 && 
      technicalDataMap['att_TensioneMax'] && technicalDataMap['att_TensioneMax'].length > 0 &&
      technicalDataMap['att_Tensione'] && technicalDataMap['att_Tensione'].length > 0
    ) {
    technicalDataMap['att_Tensione'] = [];
  }

  return technicalDataMap;
}

const getProductUrl = async (categoryId : string, productId : string, name: string, storeId: string, langId: string, nuLocale: string, params: any) => {
  let parentCategoryId = "";
  let parentCategoryName = "";
  let parentParentCategoryId = '';
  let _dataCategory: any = {
    _dataNotLoaded : true
  };
  let _dataParentCategory: any = {
    _dataNotLoaded : true
  };
  _dataCategory = await getCategoryDataById(storeId,langId,categoryId);
  if(_dataCategory._dataNotLoaded !== true) {
    parentCategoryId = _dataCategory.data?.categoryViewFindCategoryByUniqueId?.catalogGroupView?.[0]?.parentCatalogGroupID;
    if (parentCategoryId) {
      _dataParentCategory = await getCategoryDataById(storeId,langId,parentCategoryId);
      if(_dataParentCategory._dataNotLoaded !== true) {
        parentCategoryName = _dataParentCategory.data?.categoryViewFindCategoryByUniqueId?.catalogGroupView?.[0]?.name?.toLowerCase();
        parentParentCategoryId = _dataParentCategory.data?.categoryViewFindCategoryByUniqueId?.catalogGroupView?.[0]?.parentCatalogGroupID
      }
    }
  }
  if(!params['brand']){
    if(parentParentCategoryId === '10517'){
      params['brand'] = 'fosnova';
    }else{
      params['brand'] = 'disano';
    }
  }
  params['product'] = cleanProductUrlParam(productId + '-' + name);
  params['category'] = cleanProductUrlParam(parentCategoryId + '-' + parentCategoryName);
  let pdpUrl = buildPageUrl(PageType.PDP, params, nuLocale);
  return pdpUrl !== undefined ? pdpUrl : '';
}

function getLumenOutputKCri(att_FlussoLuminosoUscenteCal : string | undefined,att_Cct : string | undefined, att_CriMkt : string | undefined): string | null {
  let lumenOutputKCri:string | undefined = '';
  let flussoluminoso = att_FlussoLuminosoUscenteCal + '-';
  if(flussoluminoso.trim() === '-')
    lumenOutputKCri = (!att_Cct ? '': att_Cct) + ' - ' + (!att_CriMkt ? '': att_CriMkt);
  else
    lumenOutputKCri = att_FlussoLuminosoUscenteCal + ' - ' + (!att_Cct ? '': att_Cct) + ' - ' + (!att_CriMkt ? '': att_CriMkt);
    if(lumenOutputKCri.includes('undefined')){
      return ' ';
  }

  return lumenOutputKCri;
}

/**
 * Use the envinroment variabile to build the right url in the FE for azure asset resources
 * @param assetPath
 * @returns
 */
export const normalizeAzureAssetUrl = function (assetPath:string, suffix: string) {
  const hclHostAssetsURL = process.env.NEXT_PUBLIC_HCL_HOST_ASSETS;
  let normalized = '';
  if (hclHostAssetsURL){
    const hclHostAssets = new URL(hclHostAssetsURL).hostname;
    normalized = assetPath?.replace('https://' + hclHostAssets + suffix, '');
  }

  return normalized;
};


