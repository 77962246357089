import { useRouter } from "next/router";

/**
 * Given a locale param from next.js getStaticProps,
 * return a LanguageCodeFilterEnum equivalent string to query WPGraphQL Polylang.
 */
export const getLanguageCodeFromNextLocale = (nextLocale: string | undefined): string | undefined => {
  return nextLocale?.toUpperCase();
}

/**
 * Returns the current locale language slug given from the NextJs Router.
 * For example if the current page is "/it/p/realizzazioni" it will return "it".
 * Note: the returned value is not related to the CMS page content language.
 */
export const useCurrentLocaleLang = () => {
  const { locale } = useRouter();
  return locale;
}
