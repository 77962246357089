
import { gql } from '@apollo/client'

/**
 * Get the list of all posts.
 * Returns only base info for getStaticPaths.
 */
export const GET_POSTS = gql`
query GET_POSTS {
  posts: posts(first: 9000) {
    nodes {
      id
      slug
      uri
      language {
        id
        code
        slug
        locale
        name
      }
      categories(first: 1000) {
        nodes {
          id
          databaseId
          slug
          parentId
        }
      }
    }
  }
}
`;

/**
 * Get the list of all posts assigned to the given categories (Array of category databaseId).
 * Returns only base info for getStaticPaths.
 */
 export const GET_POSTS_IN_CATEGORIES = gql`
 query GET_POSTS_IN_CATEGORIES($categoryIn: [ID]) {
   posts: posts(first: 9000, where: { categoryIn: $categoryIn }) {
     nodes {
       id
       slug
       uri
       language {
        ...LanguageFragment
       }
       categories(first: 1000) {
         nodes {
           id
           databaseId
           slug
           parentId
         }
       }
     }
   }
 }
 `;

