const FasciaRealizzazioniListPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_RealizzazioniList`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      category {
        id
        databaseId
        name
        slug
      }
      showCategoryFilters: showcategoryfilters
      showFeaturedOnly: showfeaturedonly
      showViewAllLink: showviewalllink
      sliderOnMobile: slideronmobile
      title
    }
  `
}

export default FasciaRealizzazioniListPartial;

export interface WPRealizzazioniList {
  category: {
    id: string
    databaseId: number
    name: string
    slug: string
  }
  showCategoryFilters?: boolean
  showFeaturedOnly?: boolean
  showViewAllLink?: boolean
  sliderOnMobile?: boolean
  title?: string
}
