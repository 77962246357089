const ImageFragment = `
	fragment ImageFragment on MediaItem {
    id
    altText
    mimeType
    mediaItemUrl
    mediaDetails {
      width
      height
    }
	}
`
export default ImageFragment;

export interface WPImage {
  id: string
  altText?: string
  mimeType: string
  mediaItemUrl: string
  mediaDetails: {
    width: number
    height: number
  }
}
