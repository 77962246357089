import { WPImage } from "./image";
import LinksPartial, { WPLink } from "./link";

const FasciaHeroPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_Hero`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      height
      slides {
        fieldGroupName
        backgroundImage: backgroundimage {
          ...ImageFragment
        }
        occhiello
        title
        desc
        textLightness: textlightness
        links {
          ${LinksPartial(`${fasciaBaseType}_slides_Links`)}
        }
      }
    }
	`
}

export default FasciaHeroPartial;

export enum WPHeroHeight {
  main = 'main',
  medium = 'medium',
  small = 'small',
}

export enum WPHeroTextLightness {
  light = 'light',
  dark = 'dark',
}

export interface WPHeroSlide {
  backgroundImage: WPImage;
  occhiello?: string;
  title?: string;
  desc?: string;
  textLightness?: WPHeroTextLightness;
  links?: WPLink[];
}

export interface WPHero {
  height?: WPHeroHeight;
  slides: WPHeroSlide[];
}
