const FasciaSpacerPartial = (baseType: string) => {
  const fasciaBaseType = `${baseType}_Spacer`;
  return `
    ... on ${fasciaBaseType} {
      fieldGroupName
      amount
    }
  `
}

export default FasciaSpacerPartial;

export interface WPSpacer {
  amount: number
}
