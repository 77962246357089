
import { gql } from '@apollo/client'

/**
 * Get product details by productId.
 */
 export const GET_PRODUCT_BY_CATEGORY_ID = gql`
 query GET_PRODUCT_BY_CATEGORY_ID( $storeId: String!, $langId: String, $categoryId: String!,  $orderBy: String, $pageNumber: String, $pageSize: String) {
      productViewFindProductsByCategory(storeId: $storeId,  langId: $langId, categoryId: $categoryId,  orderBy: $orderBy, pageNumber: $pageNumber, pageSize: $pageSize, searchSource: "Q"){
        recordSetStartNumber
        recordSetCount
        recordSetTotal
        recordSetTotalMatches
        facetView {
            name
            value
            extendedData {
                srchattridentifier
            }
            entry {
                count
                label
                value
            }
        }
        catalogEntryView {
            name
            shortDescription
            partNumber
            uniqueID
            thumbnail
            parentCatalogGroupID
            attributes {
                identifier
                uniqueID
                usage
                values {
                    value
                }
            }
        }
    }
 }
 `;

export const GET_PRODUCT_BY_CATEGORY_ID_AND_FILTERED_VALUE = gql`
query GET_PRODUCT_BY_CATEGORY_ID_AND_FILTERED_VALUE( $storeId: String!, $langId: String, $categoryId: String!, $facet: String, $orderBy: String, $pageNumber: String, $pageSize: String) {
     productViewFindProductsByCategory(storeId: $storeId,  langId: $langId, categoryId: $categoryId, facet: $facet, orderBy: $orderBy, pageNumber: $pageNumber, pageSize: $pageSize, searchSource: "Q"){
        recordSetStartNumber
        recordSetCount
        recordSetTotal
        recordSetTotalMatches
        facetView {
            name
            value
            extendedData {
                srchattridentifier
            }
            entry {
                count
                label
                value
            }
        }
       catalogEntryView {
           name
           shortDescription
           partNumber
           uniqueID
           thumbnail
           parentCatalogGroupID
           attributes {
               identifier
               uniqueID
               usage
               values {
                   value
               }
           }
       }
   }
}
`;